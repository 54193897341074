import config from "@/core/config/menu.config.json";

// action types
export const SET_MENU_CONFIG = "setMenuConfig";
export const RESET_MENU_CONFIG = "resetMenuConfig";
export const OVERRIDE_MENU_CONFIG = "overrideMenuConfig";

export default {
  state: {
    config: config,
    initial: config,
  },
  getters: {
    /**
     * Get config from menu config
     * @param state
     * @returns {function(path, defaultValue): *}
     */
    menuConfig: (state) => () => {
      return state.config;
    },
  },
  actions: {
    [SET_MENU_CONFIG](state, payload) {
      state.commit(SET_MENU_CONFIG, payload);
    },
    [RESET_MENU_CONFIG](state) {
      state.commit(RESET_MENU_CONFIG);
    },
    [OVERRIDE_MENU_CONFIG](state) {
      state.commit(OVERRIDE_MENU_CONFIG);
    },
  },
  mutations: {
    [SET_MENU_CONFIG](state, payload) {
      state.config = payload;
    },
    [OVERRIDE_MENU_CONFIG](state) {
      state.config = state.initial = Object.assign(
        {},
        state.initial,
        JSON.parse(localStorage.getItem("menu_config"))
      );
    },
  },
};
