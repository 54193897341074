import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/home",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
        },
        {
          path: "/faq",
          name: "faq",
          component: () => import("@/view/pages/faq/faq.vue"),
          children: [
            {
              path: "general-definition",
              name: "general-definition",
              component: () => import("@/view/pages/faq/GeneralDefinition"),
            },
            {
              path: "rebuttal-statement",
              name: "rebuttal-statement",
              component: () => import("@/view/pages/faq/RebuttalStatement"),
            },
            {
              path: "user-guide",
              name: "user-guide",
              component: () => import("@/view/pages/faq/UserGuide"),
            },
          ],
        },
        {
          path: "complaint-data",
          name: "complaint-data",
          component: () => import("@/view/pages/complaint-data/complaint-data"),
          children: [
            {
              path: "data-consultation",
              name: "data-consultation",
              component: () => import("@/view/pages/complaint-data/complaint-data-consultation"),
            },
            {
              path: "complaint-receipt-data",
              name: "complaint-receipt-data",
              component: () =>
                import("@/view/pages/complaint-data/complaint-receipt-data"),
            },
            {
              path: "complaint-data-application",
              name: "complaint-data-application",
              component: () =>
                import(
                  "@/view/pages/complaint-data/complaint-data-application"
                ),
            },
            {
              path: "complaint-data-application-internal",
              name: "complaint-data-application-internal",
              component: () =>
                  import(
                      "@/view/pages/complaint-data/complaint-data-application-internal"
                      ),
            },
            {
              path: "complaint-data-application-eksternal",
              name: "complaint-data-application-eksternal",
              component: () =>
                  import(
                      "@/view/pages/complaint-data/complaint-data-application-eksternal"
                      ),
            },
          ],
        },
        {
          path: "periodic-data",
          name: "periodic-data",
          component: () => import("@/view/pages/periodic-data/periodic-data"),
          children: [
            {
              path: "actual-data",
              name: "actual-data",
              component: () => import("@/view/pages/periodic-data/ActualData"),
            },
            {
              path: "graph-data",
              name: "graph-data",
              component: () => import("@/view/pages/periodic-data/graph-data"),
            },
          ],
        },
        {
          path: "/survey",
          name: "survey",
          component: () => import("@/view/pages/suvey/survey"),
        },
        {
          path: "/contact-us",
          name: "contact-us",
          component: () => import("@/view/pages/contact-us/ContactUs.vue"),
        },
        {
          name: "profile",
          path: "/profile",
          component: () => import("@/view/pages/auth/Profile"),
        },
        {
          name: "changePassword",
          path: "/changePassword",
          component: () => import("@/view/pages/auth/ChangePassword"),
        },
        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue"),
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue"),
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue"),
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register"),
        },
        {
          name: "forgotPassword",
          path: "/forgotPassword",
          component: () => import("@/view/pages/auth/ForgotPassword"),
        },
      ],
    },
    {
      name: "home",
      path: "/home",
      component: () => import("@/view/pages/home/home"),
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
