<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import {
  SET_USER_ONLINE,
  SET_USER_OFFLINE,
  SET_USER_STATISTIC,
  SET_PERCENT_SURVEY,
} from "@/core/services/store/auth.module";
import { mapGetters } from "vuex";

export default {
  name: "MetronicVue",
  async mounted() {
    this.$session.start();
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    await this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    await this.$store.dispatch(SET_USER_ONLINE, this.$session.id());
    await this.$store.dispatch(SET_USER_STATISTIC);
    await this.$store.dispatch(SET_PERCENT_SURVEY);
    document.addEventListener("beforeunload", this.handlerClose);

    // Set background image
    // console.log(this.$route.name);
    if(this.$route.name!=='home'){
      const bg = this.layoutConfig("self.body.background-image");
      document.body.style.backgroundImage = `url('${bg}')`;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    async handlerClose() {
      await this.$store.dispatch(SET_USER_OFFLINE, this.$session.id());
    },
  },
};
</script>
