// INDONESIA
export const locale = {
  MESSAGES: {
    SERVER_ERROR: "Gagal akses, periksa koneksi internet anda",
    AUTH: {
      INVALID_ACCOUNT:
        "Gagal masuk ke dalam akun, periksa kembali username dan password anda",
      VERIFICATION:
        "User belum terverifikasi.",
    },
    BETA_TITLE: "Public Beta Version",
    BETA_TEXT: "Aplikasi ini dalam versi beta.",
    POP_UP_WELCOME:
      "Untuk dapat mengakses data aduan HAM secara rinci, silahkan melakukan register dengan klik daftar kemudian login. Lengkapi persyaratan yang diperlukan",
    POP_UP_WELCOME_FREE: "Gratis!",
    POP_UP_WELCOME_SURVEY:
      "Mohon isi survey penggunaan aplikasi dengan klik Survey",
    POP_UP_WELCOME_SURVEY_ALL: "Selengkapnya klik ",
    POP_UP_WELCOME_SURVEY_HERE: "disini",
    POP_UP_WELCOME_SURVEY_CLOSE: "Tutup",
    ERROR_MESSAGE: "Oops! Terjadi kesalahan, klik {here} untuk kembali",
  },
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "Tambah",
    ACTIONS: "Aksi",
    CREATE_POST: "Buat Post Baru",
    PAGES: "Halaman",
    FEATURES: "Features",
    DASHBOARD: "Beranda",
    APPS: "Aplikasi",
    FAQ: "FAQs",
    GENERAL_DEFINITION: "Definisi Umum",
    USER_GUIDE: "Panduan Pengguna",
    SURVEY_DATA: "Survei",
    SURVEY_DATA_FORM: "Formulir Data Survei",
    REBUTTAL_STATEMENT: "Pernyataan Sanggahan",
    COMPLAINT_DATA: "Data Aduan",
    COMPLAINT_RECEIPT_DATA: "Tata Cara dan Alur",
    COMPLAINT_RECEIPT_DATA_FORM: "Formulir Permohonan Data Aduan HAM",
    COMPLAINT_DATA_APPLICATION: "Permohonan Data Aduan",
    COMPLAINT_DASHBOARD: "Data HAM Aktual",
    COMPLAINT_CONSULTATION: "Data Layanan Konsultasi",
    PERIODIC_DATA: "Publikasi Data Periodik",
    ACTUAL_DATA: "Laporan Data Periodik",
    GRAPH_DATA: "Data Grafis",
    CONTACT_US: "Hubungi Kami",
    PERSONAL_INFORMATION: "Informasi Personal",
    PROFILE: "Profil Pengguna",
  },
  COMPLAINT_RECEIPT_DATA: {
    REGISTER_NOW:
      "Dengan melakukan pendaftaran, anda akan mendapatkan informasi statistik aduan Komnas HAM RI secara lengkap.",
    REGISTER_NOW_SECOND_PARAGRAPH: "Gratis!",
    REGISTER_NOW_TITLE: "Daftar Sekarang",
  },
  COMPLAINT_DATA_APPLICATION: {
    DATA_REQUEST_DETAIL: "Rincian Permohonan Data",
    REQUEST_PURPOSE: "Tujuan permohonan",
    REQUEST_DESCRIPTION: "Deskripsi singkat data yang dimohon",
    UPLOAD_APPLICATION_LETTER: "Unggah surat permohonan",
    UPLOAD_COVER_LETTER: "Unggah surat pengantar",
    TERMS1:
      "*1. Surat Permohonan dibubuhkan tanda tangan pemohon/atasan pemohon.",
    TERMS2:
      " 2. Apabila Permohonan disampaikan atas nama institusi, pemohon wajib melampirkan surat keterangan/pengantar dari institusi asal.",
    TERMS3: " 3. Dokumen yang diunggah maksimal berukuran 10 MB.",
    AGREE_TERMS:
      "Saya menyatakan bahwa seluruh informasi yang diperoleh akan digunakan untuk kepentingan Hak Asasi Manusia",
    SUBMIT: "KIRIM",
    HISTORY: "Riwayat Akses Data",
    SUBMIT_MESSAGE: "Permohonan data aduan berhasil dikirim",
    SUBMIT_ERROR: "Permohonan data aduan gagal dikirim",
  },
  GENERAL_DEFINITION: {
    TERM: "Istilah",
    DEFINITION: "Definisi",
  },
  CONSULTATION_FILTER: {
    SELECT_MONTH: "-- Pilih Bulan --",
    SELECT_YEAR: "-- Pilih Tahun --",
    SELECT_COUNTRY: "-- Pilih Negara --",
    SELECT_PROVINCE: "-- Pilih Provinsi --",
    SELECT: "-- Pilih --",
    PERIOD: "Period",
    LOCATION: "Asal Pengadu",
    PROVINSI: "Provinsi",
    MEDIA: "Media Konsultasi",
    TEMA: "Tema Konsultasi",
    ISU: "Isu",
    KONSULTASI_MEDIA: "Media Konsultasi",
    KONSULTASI_TEMA: "Tema Konsultasi",
    KONSULTASI_ISU: "Isu",
    FILTER_TITLE: "Filter",
  },
  DASHBOARD_FILTER: {
    SELECT_MONTH: "-- Pilih Bulan --",
    SELECT_YEAR: "-- Pilih Tahun --",
    SELECT_COUNTRY: "-- Pilih Negara --",
    SELECT_PROVINCE: "-- Pilih Provinsi --",
    SELECT_ISU: "-- Pilih Isu --",
    SELECT_CASE_DETAIL: "-- Pilih Detil Aduan --",
    SELECT_RIGHT: "-- Pilih Hak --",
    SELECT_VICTIM: "-- Pilih Korban --",
    SELECT_DEFENDANT: "-- Pilih Klasifikasi Pihak yang Diadukan --",
    SELECT_COMPLAINANT: "-- Pilih Pengadu --",
    SELECT_SPECIAL_EVENT: "-- Pilih Kejadian Khusus --",
    PERIOD: "Periode",
    LOCATION: "Lokasi Kejadian",
    ISSUE: "Isu",
    CASE_DETAIL: "Detil Kasus",
    RIGHT_CLASSIFICATION: "Klasifikasi Hak",
    VICTIM_CLASSIFICATION: "Klasifikasi Korban",
    DEFENDANT_CLASSIFICATION: "Klasifikasi Pihak yang Diadukan",
    COMPLAINANT_CLASSIFICATION: "Klasifikasi Pengadu",
    SPECIAL_EVENT: "Kejadian Khusus",
  },
  DASHBOARD: {
    DATA_CALCULATION: "Kalkulasi Data",
    DATA_CALCULATION_NOTIFICATION: "Data telah di kalkulasi sampai dengan",
    DOWNLOAD_TITLE: "Unduh Semua Data",
    DOWNLOAD_DESCRIPTION:
      "Anda dapat melakukan unduh data. Lakukan filter data dan klik pada link Unduh Data di bawah ini.",
    DOWNLOAD_LINK: "Unduh Data",
    MAPS: {
      TITLE: "Sebaran Wilayah Peristiwa",
      PERIOD: "Periode {period1} s.d {period2}",
      DESCRIPTION: "Data sebaran berdasarkan provinsi",
      MONTHLY: "Bulanan",
      YEARLY: "Tahunan",
      WEEKLY: "Mingguan",
      STATE: "Provinsi: {prov1}",
      COUNT: "Jumlah Aduan: {case1}",
      COUNT_MAP: "{case1} Aduan",
      TITLE_TOTAL_CASE: "Jumlah Aduan {prov}",
      LEGEND_1: "Urutan terbanyak no 1 sampai dengan 5",
      LEGEND_2: "Urutan terbanyak no 6 dan seterusnya",
      LEGEND_3: "Provinsi dengan angka 0",
      LEGEND_HEADER: "Pewarnaan Peta",
    },
    YEARLY_CHART: {
      TITLE: "Statistik Tahunan",
      DESCRIPTION: "Statistik pengaduan 3 tahun terakhir",
      CASES_COUNT: "Jumlah Aduan",
    },
    MONTHLY_CHART: {
      TITLE: "Statistik Bulanan",
      DESCRIPTION: "Tahun {monthlyChart1}",
      MONTH: "Bulan",
      COUNT: "Jumlah Aduan",
    },
  },
  SPECIAL_EVENT: {
    TITLE: "Peristiwa Khusus",
  },
  CASE_DETAIL: {
    TITLE: "Detil Kasus",
  },
  RIGHT_CLASSIFICATION: {
    TITLE: "Klasifikasi Hak",
  },
  DEFENDANT_CLASSIFICATION: {
    TITLE: "Klasifikasi Pihak yang Diadukan",
  },
  ISSUE: {
    TITLE: "Isu Kasus",
  },
  CLAIMANT_CLASSIFICATION: {
    TITLE: "Klasifikasi Pengadu",
  },
  SUBMISSION_METHOD: {
    TITLE: "Cara Penyampaian Aduan",
  },
  VICTIM_CLASSIFICATION: {
    TITLE: "Klasifikasi Korban",
  },
  CASE_BY_COUNTRY: {
    TITLE: "Sebaran Kasus Dunia",
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Belum memiliki akun?",
      SIGNUP_BUTTON: "Daftar",
      FORGOT_BUTTON: "Lupa Password",
      BACK_BUTTON: "Kembali",
      PRIVACY: "Privasi",
      LEGAL: "Legal",
      CONTACT: "Kontak",
      PLEASE_LOGIN: "Anda belum login",
      GUESS: "Tamu",
      HAVING_ACCOUNT: "Sudah memiliki akun?",
    },
    USER_PROFILE: {
      TITLE: "Profil Pengguna",
      MESSAGE: "Pesan",
      MY_PROFILE: "Profil Saya",
      USER_INFO: "Info Pengguna",
      PICTURE: "Foto Profil",
      SUBMIT_EDIT: "Profil berhasil diubah",
      ERROR_EDIT: "Profil gagal disimpan",
      BTN_CHANGE_PASS: "Ubah Password",
      BTN_CHANGE_EDIT: "Ubah Profil",
      TITLE_CHANGE_PASS: "Ubah Password",
    },
    USER_STATISTIC: {
      TITLE: "Statistik Pengguna",
      TODAY: "Hari ini",
      YESTERDAY: "Kemarin",
      MONTH: "Bulan ini",
      YEAR: "Tahun ini",
      TOTAL: "Total",
      DATA: "{param1} Pengguna",
    },
    LOGIN: {
      TITLE: "Masukkan Username dan Password",
      NOT_HAVE_ACCOUNT: "Belum memiliki akun?",
      BUTTON: "Masuk",
    },
    FORGOT: {
      TITLE: "Lupa password ?",
      DESC: "Masukkan alamat email untuk mengatur ulang password anda",
      SUCCESS: "Password akun anda telah di atur ulang.",
    },
    CHANGE_PASSWORD: {
      TITLE_ASK: "Apa kamu setuju?",
      TITLE_MSG: "Untuk keamanan, diharapkan untuk mengganti password secara berkala",
      CONFIRM_BTN : "Ubah sandi!",
      CANCEL_BTN : "Tidak",
    },
    REGISTER: {
      TITLE: "Daftar",
      DESC: "Masukkan data anda untuk membuat akun",
      SUCCESS: "Akun anda telah didaftarkan.",
      ENTER_ACCOUNT: "Masukkan data anda untuk mendaftar",
      REJECT_EMAIL: "Email sudah digunakan, mohon gunakan email lain",
      REJECT_USERNAME: "Username sudah digunakan, mohon gunakan Username lain",
      ACCEPT_REGISTER: "Permohonan registrasi anda diterima.",
      ACCEPT_REGISTER2: "Untuk verifikasi kami telah mengirimkan TOKEN ke email anda. Silahkan periksan email anda dan klik pada tombol 'Tutup' "
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Nama Lengkap",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Ketik Ulang Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{name} tidak valid",
      REQUIRED: "{name} wajib diisi",
      MIN_LENGTH: "{name} minimal karakter : {min}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "Permintaan {name} tidak ditemukan",
      INVALID_LOGIN: "User name dan password tidak cocok",
      REQUIRED_FIELD: "wajib",
      MIN_LENGTH_FIELD: "minimal :",
      MAX_LENGTH_FIELD: "maksimal :",
      INVALID_FIELD: "Data tidak valid",
    },
  },
  GENERAL_TEXT: {
    CASES: "Aduan",
    LOAD_MORE_DATA: "Muat Seluruh Data",
    CASE_COUNT: "Jumlah Aduan",
    YEAR: "Tahun",
    DOWNLOAD: "Unduh",
    VIEW: "Lihat",
  },
  FOOTER: {
    USER_FEEDBACK: "Tanggapan Pengguna",
    SATISFIED: "PUAS",
    NEUTRAL: "BIASA SAJA",
    UNSATISFIED: "TIDAK PUAS",
    CONTACT_US: "Hubungi Kami",
    SURVEY_PERCENT: "{param1} %",
  },
  SURVEY: {
    EMAIL: {
      LABEL: "Email",
    },
    PENGGUNAAN_APLIKASI: {
      LABEL: "Penggunaan Aplikasi",
      OPTLABEL1: "puas",
      OPTLABEL2: "Biasa saja",
      OPTLABEL3: "Tidak puas",
    },
    JENISKELAMIN: {
      LABEL: "Jenis kelamin",
      OPTLABEL1: "Laki-laki",
      OPTLABEL2: "Perempuan",
    },
    USIA: {
      LABEL: "Usia",
      OPTLABEL1: "< 18 Tahun",
      OPTLABEL2: "18 - 30 Tahun",
      OPTLABEL3: "30 - 50 Tahun",
      OPTLABEL4: "> 50 Tahun",
    },
    PENDIDIKAN: {
      LABEL: "Pendidikan",
      OPTLABEL1: "SD/Sederajat",
      OPTLABEL2: "SMP/Sederajat",
      OPTLABEL3: "SMA/Sederajat",
      OPTLABEL4: "DIII",
      OPTLABEL5: "S1/DIV",
      OPTLABEL6: "S2",
      OPTLABEL7: "S3",
    },
    SARAN: {
      LABEL: "Saran",
    },
    BTN: {
      LABEL: "Kirim",
    },
  },
};
