import ApiService from "@/core/services/api.service";

export const GET_PROPOSAL_TYPE = "getProposalType";
export const SET_PROPOSAL_TYPE_SELECTED = "setProposalTypeSelected";

export const GET_DOWNLOAD_HISTORY = "getDownloadHistory";
export const SET_DOWNLOAD_HISTORY_PAGE = "setDownloadHistoryPage";
export const GET_DOWNLOAD_DOCUMENT_LIST = "getDownloadDocumentList";
export const SET_DOWNLOAD_HISTORY_FILTER = "setDownloadHistoryFilter";
export const SET_DOWNLOAD_HISTORY_SORT = "setDownloadHistorySort";

export const SET_DOWNLOAD_HISTORY = "setDownloadHistory";
export const SET_DOWNLOAD_HISTORY_LOADING = "setDownloadHistoryLoading";
export const SET_DOWNLOAD_DOCUMENT_LIST = "setDownloadDocumentList";

export const SET_PROPOSAL_TYPE = "setProposalType";

const state = {
  proposalTypes: [],
  proposalTypeSelected: 0,
  downloadHistory: [],
  downloadDocumentList: [],
  downloadHistoryCurrentPage: 0,
  downloadHistoryTotalPage: 0,
  downloadHistoryPerPage: 10,
  downloadHistoryTotalRow: 0,
  downloadHistoryIsLoading: false,
  downloadHistoryFilter: "",
  downloadHistorySort: {},
};

const getters = {
  getProposalType: (state) => {
    return state.proposalTypes;
  },
  getDownloadHistory: (state) => {
    return state.downloadHistory;
  },
  getDownloadHistoryLoadingStatus: (state) => {
    return state.downloadHistoryIsLoading;
  },
  getDownloadHistoryCurrentPage: (state) => {
    return state.downloadHistoryCurrentPage;
  },
  getDownloadHistoryTotalPage: (state) => {
    return state.downloadHistoryTotalPage;
  },
  getDownloadHistoryPerPage: (state) => {
    return state.downloadHistoryPerPage;
  },
  getDownloadHistoryTotalRow: (state) => {
    return state.downloadHistoryTotalRow;
  },
  getDownloadDocumentList: (state) => {
    return state.downloadDocumentList;
  },
};

const actions = {
  [GET_PROPOSAL_TYPE](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/constant/proposal_type")
        .then(({ data }) => {
          // context.commit(SET_PROPOSAL_TYPE, Object.values(data));
          // resolve(Object.values(data));
          context.commit(SET_PROPOSAL_TYPE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [SET_PROPOSAL_TYPE_SELECTED](context, param) {
    context.commit(SET_PROPOSAL_TYPE_SELECTED, param);
  },
  [GET_DOWNLOAD_HISTORY](context) {
    context.commit(SET_DOWNLOAD_HISTORY_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`api/smartmap/download_history`, {
        "pages": state.downloadHistoryPerPage,
        "sort": state.downloadHistorySort,
        "filter": {
          "download_name": state.downloadHistoryFilter,
        },
        "page": state.downloadHistoryCurrentPage,
      })
        .then(({ data }) => {
          context.commit(SET_DOWNLOAD_HISTORY, data);
          context.commit(SET_DOWNLOAD_HISTORY_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_DOWNLOAD_HISTORY_LOADING, false);
          reject(response);
        });
    });
  },
  [GET_DOWNLOAD_DOCUMENT_LIST](context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`api/smartmap/download_history`, {
        "pages": state.downloadHistoryPerPage,
        "sort": state.downloadHistorySort,
        "filter": {
          "download_name": state.downloadHistoryFilter,
        },
        "page": state.downloadHistoryCurrentPage,
      })
          .then(({ data }) => {
            context.commit(SET_DOWNLOAD_DOCUMENT_LIST, data);
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  [SET_DOWNLOAD_HISTORY_PAGE](context, page) {
    context.commit(SET_DOWNLOAD_HISTORY_PAGE, page);
    context.dispatch(GET_DOWNLOAD_HISTORY);
  },
  [SET_DOWNLOAD_HISTORY_FILTER](context, text) {
    context.commit(SET_DOWNLOAD_HISTORY_FILTER, text);
    context.dispatch(GET_DOWNLOAD_HISTORY);
  },
  [SET_DOWNLOAD_HISTORY_SORT](context, param) {
    context.commit(SET_DOWNLOAD_HISTORY_SORT, param);
    context.dispatch(GET_DOWNLOAD_HISTORY);
  },
};

const mutations = {
  [SET_PROPOSAL_TYPE](state, data) {
    state.proposalTypes = data;
  },
  [SET_PROPOSAL_TYPE_SELECTED](state, data) {
    state.proposalTypeSelected = data;
  },
  [SET_DOWNLOAD_HISTORY](state, data) {
    state.downloadHistory = data.data;
    state.downloadHistoryCurrentPage = data.meta.current_page;
    state.downloadHistoryTotalPage = data.meta.total_page;
    state.downloadHistoryTotalRow = data.meta.total;
  },
  [SET_DOWNLOAD_HISTORY_PAGE](state, page) {
    state.downloadHistoryCurrentPage = page;
  },
  [SET_DOWNLOAD_HISTORY_FILTER](state, filter) {
    state.downloadHistoryFilter = filter;
  },
  [SET_DOWNLOAD_HISTORY_SORT](state, sort) {
    state.downloadHistorySort = sort;
  },
  [SET_DOWNLOAD_HISTORY_LOADING](state, status) {
    state.downloadHistoryIsLoading = status;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};


