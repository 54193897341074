// INDONESIA
export const locale = {
  MESSAGES: {
    SERVER_ERROR: "Failed access, please check your internet connection",
    AUTH: {
      INVALID_ACCOUNT:
        "Failed to login, please check your username and password",
      VERIFICATION:
          "User has not been verified.",
    },
    BETA_TITLE: "Public Beta Version",
    BETA_TEXT: "This apps is in beta version",
    POP_UP_WELCOME:
      "To be able to access complaint data of human rights in detail, please do register with click list and then login. Complete the requirements.",
    POP_UP_WELCOME_FREE: "Free!",
    POP_UP_WELCOME_SURVEY: "Please fill in the survey.",
    POP_UP_WELCOME_SURVEY_ALL: "For more Information click",
    POP_UP_WELCOME_SURVEY_HERE: "here",
    POP_UP_WELCOME_SURVEY_CLOSE: "Close",
    ERROR_MESSAGE: "Oops! Something wrong, click {here} to return",
  },
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "Add",
    ACTIONS: "Action",
    CREATE_POST: "Create new post",
    PAGES: "Pages",
    FEATURES: "Features",
    DASHBOARD: "Home",
    APPS: "Apps",
    FAQ: "FAQs",
    GENERAL_DEFINITION: "General definition",
    USER_GUIDE: "User Guides",
    SURVEY_DATA: "Survey",
    SURVEY_DATA_FORM: "Survey Data Form",
    REBUTTAL_STATEMENT: "Disclaimer",
    COMPLAINT_DATA: "Complaint Data",
    COMPLAINT_RECEIPT_DATA: "Request Data Procedure",
    COMPLAINT_RECEIPT_DATA_FORM: "Complaint Data Request Form",
    COMPLAINT_DATA_APPLICATION: "Request Form",
    COMPLAINT_DASHBOARD: "Actual HAM Data",
    COMPLAINT_CONSULTATION: "Consulting Service Data",
    PERIODIC_DATA: "Periodic Data Publication",
    ACTUAL_DATA: "Periodic Data Report",
    GRAPH_DATA: "Infographics",
    CONTACT_US: "Contact Us",
    PERSONAL_INFORMATION: "Personal Information",
    PROFILE: "User Profile",
  },
  COMPLAINT_RECEIPT_DATA: {
    REGISTER_NOW:
      "By registration, you will get the statistical information in full.",
    REGISTER_NOW_SECOND_PARAGRAPH: "Free!",
    REGISTER_NOW_TITLE: "Register Now",
  },
  COMPLAINT_DATA_APPLICATION: {
    DATA_REQUEST_DETAIL: "Request Data detail",
    REQUEST_PURPOSE: "Request Purpose",
    REQUEST_DESCRIPTION: "Request Description",
    UPLOAD_APPLICATION_LETTER: "Upload Aplication Letter",
    UPLOAD_COVER_LETTER: "Upload Recommendation Letter",
    TERMS1:
      "*1. The Application Letter is affixed with the signature of the applicant / applicant's superior.",
    TERMS2:
      " 2. If the application is submitted on behalf of the institution, the applicant must attach a statement/introduction letter from the institution of origin.",
    TERMS3: " 3. The maximum size of the uploaded document is 10 MB.",
    AGREE_TERMS:
      "I declare that all the information obtained will be used for the benefit of human rights",
    SUBMIT: "SEND",
    HISTORY: "History of Data Access",
    SUBMIT_MESSAGE: "Application sent successfully.",
    SUBMIT_ERROR: "Application failed to sent.",
  },
  GENERAL_DEFINITION: {
    TERM: "Definition",
    DEFINITION: "Definition",
  },
  CONSULTATION_FILTER: {
    SELECT_MONTH: "-- Select Month --",
    SELECT_YEAR: "-- Select Year --",
    SELECT_COUNTRY: "-- Select Country --",
    SELECT_PROVINCE: "-- Select Province --",
    SELECT: "-- Select --",
    PERIOD: "Period",
    LOCATION: "Origin Complaint",
    PROVINSI: "Province",
    MEDIA: "Consulting Media",
    TEMA: "Consulting Theme",
    ISU: "Issue",
    KONSULTASI_MEDIA: "Consulting Media",
    KONSULTASI_TEMA: "Consulting Theme",
    KONSULTASI_ISU: "Issue",
    FILTER_TITLE: "Filter",
  },
  DASHBOARD_FILTER: {
    SELECT_MONTH: "-- Select Month --",
    SELECT_YEAR: "-- Select Year --",
    SELECT_COUNTRY: "-- Select Country --",
    SELECT_PROVINCE: "-- Select Province --",
    SELECT_ISU: "-- Select Issue --",
    SELECT_CASE_DETAIL: "-- Select Complaint Detail --",
    SELECT_RIGHT: "-- Select Rights --",
    SELECT_VICTIM: "-- Select Victim --",
    SELECT_DEFENDANT: "-- Select Offender Classifitation --",
    SELECT_COMPLAINANT: "-- Select Complainant --",
    SELECT_SPECIAL_EVENT: "-- Select Special Ocassion --",
    PERIOD: "Period",
    LOCATION: "Location",
    ISSUE: "Issue",
    CASE_DETAIL: "Complaint Detail",
    RIGHT_CLASSIFICATION: "Classification of Rights",
    VICTIM_CLASSIFICATION: "Victims Classification",
    DEFENDANT_CLASSIFICATION: "Offenders Classification",
    COMPLAINANT_CLASSIFICATION: "Complainants Classification",
    SPECIAL_EVENT: "Special Ocassion",
  },
  DASHBOARD: {
    DATA_CALCULATION: "Data Calculation",
    DATA_CALCULATION_NOTIFICATION: "Data have been calculated to",
    DOWNLOAD_TITLE: "Download Data",
    DOWNLOAD_DESCRIPTION:
      "You can download the data. Use advance search then click on the download link below.",
    DOWNLOAD_LINK: "Download Data",
    MAPS: {
      TITLE: "Complaint Distribution Area",
      PERIOD: "Period {period1} s.d {period2}",
      DESCRIPTION: "Distribution Area by Province",
      MONTHLY: "Monthly",
      YEARLY: "Yearly",
      WEEKLY: "Weekly",
      STATE: "Province: {prov1}",
      COUNT: "Number of Complaints: {case1}",
      COUNT_MAP: "{case1} Complaint",
      TITLE_TOTAL_CASE: "Number of Complaints {prov}",
      LEGEND_1: "Ranking 1 to 5",
      LEGEND_2: "Ranking 6 to 34",
      LEGEND_3: "Provinsi with 0 complaint",
      LEGEND_HEADER: "Legend",
    },
    YEARLY_CHART: {
      TITLE: "Annual Statistics",
      DESCRIPTION: "Complaint Statistics for last 3 years",
      CASES_COUNT: "Number of Complaints",
    },
    MONTHLY_CHART: {
      TITLE: "Monthly Statistics",
      DESCRIPTION: "year {monthlyChart1}",
      MONTH: "Month",
      COUNT: "Number of Complaints",
    },
  },
  SPECIAL_EVENT: {
    TITLE: "Special Ocassion",
  },
  CASE_DETAIL: {
    TITLE: "Complaints Detail",
  },
  RIGHT_CLASSIFICATION: {
    TITLE: "Clasification of Rights",
  },
  DEFENDANT_CLASSIFICATION: {
    TITLE: "Classification of Offenders",
  },
  ISSUE: {
    TITLE: "Issue",
  },
  CLAIMANT_CLASSIFICATION: {
    TITLE: "Classsification of Complainants",
  },
  SUBMISSION_METHOD: {
    TITLE: "How to Submit Complaint",
  },
  VICTIM_CLASSIFICATION: {
    TITLE: "Classification of Victims",
  },
  CASE_BY_COUNTRY: {
    TITLE: "World Complaint Distribution",
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "forgot Password",
      BACK_BUTTON: "Cancel",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
      PLEASE_LOGIN: "You are not logged in",
      GUESS: "Guess",
      HAVING_ACCOUNT: "Already have an account?",
    },
    USER_PROFILE: {
      TITLE: "User Profile",
      MESSAGE: "Message",
      MY_PROFILE: "My Profile",
      USER_INFO: "User Info",
      PICTURE: "Profile Picture",
      SUBMIT_EDIT: "Profile has been saved",
      ERROR_EDIT: "Failed to save profile",
      BTN_CHANGE_PASS: "Change Password",
      BTN_CHANGE_EDIT: "Change Profile",
      TITLE_CHANGE_PASS: "Change Password",
    },
    USER_STATISTIC: {
      TITLE: "User Statistics",
      TODAY: "Today",
      YESTERDAY: "Yesterday",
      MONTH: "This month",
      YEAR: "This year",
      TOTAL: "Total",
      DATA: "{param1} Users",
    },
    LOGIN: {
      TITLE: "Enter username and password",
      NOT_HAVE_ACCOUNT: "Don't have an accoount?",
      BUTTON: "Log in",
    },
    FORGOT: {
      TITLE: "Forgot password?",
      DESC: "Enter your email address to reset your password",
      SUCCESS: "Your account has been reset",
    },
    CHANGE_PASSWORD: {
      TITLE_ASK: "Are you sure?",
      TITLE_MSG: "For security, it's recommended to change the password periodically",
      CONFIRM_BTN : "Change password!",
      CANCEL_BTN : "No",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your personal data",
      SUCCESS: "Your account has been registered",
      ENTER_ACCOUNT: "Enter your personal data",
      REJECT_EMAIL: "Email already used, please use other email",
      REJECT_USERNAME: "Username already used, please use another",
      ACCEPT_REGISTER:
        "Your registration aplication is accepted.",
      ACCEPT_REGISTER2:
        "to verify your account, we just send the TOKEN to your email inbox. Please check your email inbox and click on 'close' button",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Full name",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{name} invalid",
      REQUIRED: "{name} required",
      MIN_LENGTH: "{name} minimum caracter : {min}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "Request {name} not found",
      INVALID_LOGIN: "Username dan password didn't match",
      REQUIRED_FIELD: "required",
      MIN_LENGTH_FIELD: "minimal :",
      MAX_LENGTH_FIELD: "maximal :",
      INVALID_FIELD: "Data invalid",
    },
  },
  GENERAL_TEXT: {
    CASES: "Complaints",
    LOAD_MORE_DATA: "Load more",
    CASE_COUNT: "total",
    YEAR: "Year",
    DOWNLOAD: "Download",
    VIEW: "View",
  },
  FOOTER: {
    USER_FEEDBACK: "User feedback",
    SATISFIED: "Satisfied",
    NEUTRAL: "Neutral",
    UNSATISFIED: "Unsatisfied",
    CONTACT_US: "Contact us",
    SURVEY_PERCENT: "{param1} %",
  },
  SURVEY: {
    EMAIL: {
      LABEL: "Email",
    },
    PENGGUNAAN_APLIKASI: {
      LABEL: "Aplication use",
      OPTLABEL1: "Satisfied",
      OPTLABEL2: "Neutral",
      OPTLABEL3: "Unsatisfied",
    },
    JENISKELAMIN: {
      LABEL: "Gender",
      OPTLABEL1: "Male",
      OPTLABEL2: "Female",
    },
    USIA: {
      LABEL: "Ages",
      OPTLABEL1: "< 18 Years",
      OPTLABEL2: "18 - 30 Years",
      OPTLABEL3: "30 - 50 Years",
      OPTLABEL4: "> 50 Years",
    },
    PENDIDIKAN: {
      LABEL: "Education",
      OPTLABEL1: "Primary School",
      OPTLABEL2: "Junior High School",
      OPTLABEL3: "Senior High School",
      OPTLABEL4: "Diploma",
      OPTLABEL5: "Bachelor",
      OPTLABEL6: "Master",
      OPTLABEL7: "Doctor",
    },
    SARAN: {
      LABEL: "sugestion",
    },
    BTN: {
      LABEL: "Submit",
    },
  },
};
