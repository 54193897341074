// action type
import ApiService from "@/core/services/api.service";
import {
  SET_LOADING,
  SET_LOADING_DETAIL_DATA_LIST,
  SET_LOADING_ISSUE_DATA_LIST,
} from "./loading.module";

export const GET_ISSUE_DATA_LIST = "getIssueDataList";
export const SET_ISSUE_SELECTED = "setIssueSelected";
export const GET_THEME_DATA_LIST = "getThemeDataList";
export const SET_THEME_SELECTED = "setThemeSelected";
export const GET_MEDIA_DATA_LIST = "getMediaDataList";
export const SET_MEDIA_SELECTED = "setMediaSelected";

// mutation type
export const SET_ISSUE_DATA_LIST = "setIssueDataList";
export const SET_THEME_DATA_LIST = "setThemeDataList";
export const SET_MEDIA_DATA_LIST = "setMediaDataList";


const state = {
  issueList: [],
  themeList: [],
  mediaList: [],
  issueSelect: 0,
  themeSelect: 0,
  mediaSelect: 0,
};

const getters = {
  getConsultIssueData: (state) => {
    return state.issueList;
  },
  getConsultThemeData: (state) => {
    return state.themeList;
  },
  getConsultMediaData: (state) => {
    return state.mediaList;
  },
};

const actions = {
  [GET_ISSUE_DATA_LIST](context) {
    context.commit(SET_LOADING, true);
    context.commit(SET_LOADING_ISSUE_DATA_LIST, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("api/issue/list", {
        sort: {
          issue_name: "asc",
        },
        pages: 10000,
      })
        .then(({ data }) => {
          context.commit(SET_ISSUE_DATA_LIST, data.data);
          context.commit(SET_LOADING_ISSUE_DATA_LIST, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_THEME_DATA_LIST](context) {
    context.commit(SET_LOADING, true);
    context.commit(SET_LOADING_DETAIL_DATA_LIST, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("api/consultation_theme/list", {
        sort: {
          theme_name: "asc",
        },
        pages: 10000,
      })
        .then(({ data }) => {
          // console.log(data);
          context.commit(SET_THEME_DATA_LIST, data.data);
          context.commit(SET_LOADING_DETAIL_DATA_LIST, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_MEDIA_DATA_LIST](context) {
    context.commit(SET_LOADING, true);
    context.commit(SET_LOADING_DETAIL_DATA_LIST, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("api/constant/tipe_konsultasi")
          .then(({ data }) => {
            // console.log(data);
            context.commit(SET_MEDIA_DATA_LIST, data);
            context.commit(SET_LOADING_DETAIL_DATA_LIST, false);
            context.commit(SET_LOADING, false);
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  [SET_ISSUE_SELECTED](context, param) {
    context.commit(SET_ISSUE_SELECTED, param);
  },
  [SET_THEME_SELECTED](context, param) {
    context.commit(SET_THEME_SELECTED, param);
  },
  [SET_MEDIA_SELECTED](context, param) {
    context.commit(SET_MEDIA_SELECTED, param);
  },
};

const mutations = {
  [SET_ISSUE_DATA_LIST](state, data) {
    state.issueList = data;
  },
  [SET_THEME_DATA_LIST](state, data) {
    state.themeList = data;
  },
  [SET_MEDIA_DATA_LIST](state, data) {
    state.mediaList = data;
  },
  [SET_ISSUE_SELECTED](state, data) {
    state.issueSelect = data;
  },
  [SET_THEME_SELECTED](state, data) {
    state.themeSelect = data;
  },
  [SET_MEDIA_SELECTED](state, data) {
    state.mediaSelect = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
