import ApiService from "@/core/services/api.service";

export const GET_ACTUAL_DATA = "getActualData";
export const SET_ACTUAL_DATA_PAGE = "setActualDataPage";
export const SET_ACTUAL_DATA_FILTER = "setActualDataFilter";
export const SET_ACTUAL_DATA_SORT = "setActualDataSort";

export const SET_ACTUAL_DATA = "setActualData";
export const SET_ACTUAL_DATA_LOADING = "setActualDataLoading";

const state = {
  actualData: [],
  actualDataCurrentPage: 0,
  actualDataTotalPage: 0,
  actualDataPerPage: 10,
  actualDataTotalRow: 0,
  actualDataIsLoading: false,
  actualDataFilter: "",
  actualDataSort: {
    laporan_tahun: "desc",
  },
};

const getters = {
  getActualData: (state) => {
    return state.actualData;
  },
  getActualDataLoadingStatus: (state) => {
    return state.actualDataIsLoading;
  },
  getActualDataCurrentPage: (state) => {
    return state.actualDataCurrentPage;
  },
  getActualDataTotalPage: (state) => {
    return state.actualDataTotalPage;
  },
  getActualDataPerPage: (state) => {
    return state.actualDataPerPage;
  },
  getActualDataTotalRow: (state) => {
    return state.actualDataTotalRow;
  },
};

const actions = {
  [GET_ACTUAL_DATA](context) {
    context.commit(SET_ACTUAL_DATA_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(
        `api/smartmap/get_actual_data?page=${state.actualDataCurrentPage}`,
        {
          "filter": {
            "laporan_name": state.actualDataFilter,
          },
          "sort": state.actualDataSort,
          "pages": state.actualDataPerPage,
        }
      )
        .then(({ data }) => {
          context.commit(SET_ACTUAL_DATA, data);
          context.commit(SET_ACTUAL_DATA_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ACTUAL_DATA_LOADING, false);
          reject(response);
        });
    });
  },
  [SET_ACTUAL_DATA_PAGE](context, page) {
    context.commit(SET_ACTUAL_DATA_PAGE, page);
    context.dispatch(GET_ACTUAL_DATA);
  },
  [SET_ACTUAL_DATA_FILTER](context, text) {
    context.commit(SET_ACTUAL_DATA_FILTER, text);
    context.dispatch(GET_ACTUAL_DATA);
  },
  [SET_ACTUAL_DATA_SORT](context, param) {
    context.commit(SET_ACTUAL_DATA_SORT, param);
    context.dispatch(GET_ACTUAL_DATA);
  },
};

const mutations = {
  [SET_ACTUAL_DATA](state, data) {
    state.actualData = data.data;
    state.actualDataCurrentPage = data.meta.current_page;
    state.actualDataTotalPage = data.meta.total_page;
    state.actualDataTotalRow = data.meta.total;
  },
  [SET_ACTUAL_DATA_PAGE](state, page) {
    state.actualDataCurrentPage = page;
  },
  [SET_ACTUAL_DATA_FILTER](state, filter) {
    state.actualDataFilter = filter;
  },
  [SET_ACTUAL_DATA_SORT](state, sort) {
    state.actualDataSort = sort;
  },
  [SET_ACTUAL_DATA_LOADING](state, status) {
    state.actualDataIsLoading = status;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
