import objectPath from "object-path";
import ApiService from "../api.service";
import { SET_ERROR } from "./auth.module";

// action types
export const SET_PROVINCE = "setProvince";
export const OVERRIDE_PROVINCE = "overrideProvince";
export const SET_COUNTRY = "setCountry";
export const SET_COUNTRY_SELECTED = "setCountrySelected";
export const SET_PROVINCE_SELECTED = "setProvinceSelected";

// Mutations
export const ADD_PROVINCE = "addProvince";
export const ADD_COUNTRY = "addCountry";

export default {
  state: {
    province: [],
    countries: [],
    provinceSelected: 0,
    countrySelected: 1,
  },
  getters: {
    /**
     * Get config from layout config
     * @param state
     * @returns {function(path, defaultValue): *}
     */
    provinces: (state) => (path, defaultValue) => {
      return objectPath.get(state.province, path, defaultValue);
    },
    getProvinces: (state) => {
      return state.province;
    },
    getCountries: (state) => {
      return state.countries;
    },
  },
  actions: {
    /**
     * Set and replace the whole config
     * @param context
     */
    [SET_PROVINCE](context) {
      return new Promise((resolve, reject) => {
        ApiService.post("api/states-filter", {
          filter: {
            state_name: "",
            state_description: "",
            country_id: context.state.countrySelected,
          },
          sort: {
            state_name: "asc",
          },
          pages: 0,
        })
          .then(({ data }) => {
            context.commit(ADD_PROVINCE, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
      });
    },
    [SET_COUNTRY](context) {
      return new Promise((resolve, reject) => {
        ApiService.post("api/country/list", {
          filter: {
            country_name: "",
          },
          sort: {
            state_name: "asc",
          },
          pages: 0,
        })
          .then(({ data }) => {
            context.commit(ADD_COUNTRY, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
      });
    },
    [SET_COUNTRY_SELECTED](context, country) {
      context.commit(SET_COUNTRY_SELECTED, country);
      context.dispatch(SET_PROVINCE);
    },

    /**
     * Set and replace the whole config
     * @param state
     * @param payload
     */
    [OVERRIDE_PROVINCE](state, payload) {
      state.commit(OVERRIDE_PROVINCE, payload);
    },
  },
  mutations: {
    [ADD_PROVINCE](state, payload) {
      state.province = payload;
    },
    [OVERRIDE_PROVINCE](state, payload) {
      state.province = payload;
    },
    [ADD_COUNTRY](state, payload) {
      state.countries = payload;
    },
    [SET_COUNTRY_SELECTED](state, country) {
      state.countrySelected = country;
    },
  },
};
