export const SET_LOADING = "setLoading";

export const SET_LOADING_DASHBOARD_DATA = "setLoadingDashboardData";
export const SET_LOADING_DASHBOARD_MAPS_DATA = "setLoadingDashboadMapsData";
export const SET_LOADING_DASHBOARD_TOTAL_CASE = "setLoadingDashboardTotalCase";
export const SET_LOADING_DASHBOARD_YEARLY_CASE =
  "setLoadingDashboardYearlyCase";
export const SET_LOADING_RIGHT_CLASSIFICATION_CASE =
  "setLoadingRightClassificationCase";
export const SET_LOADING_ISSUE_CASE = "setLoadingIssueCase";
export const SET_LOADING_CASE_DETAIL = "setLoadingCaseDetail";
export const SET_LOADING_CLAIMANT_CLASSIFICATION =
  "setLoadingClaimantClassification";
export const SET_LOADING_VICTIM_CLASSIFICATION =
  "setLoadingVictimClassification";
export const SET_LOADING_DEFENDANT_CLASSIFICATION =
  "setLoadingDefendantClassification";
export const SET_LOADING_DASHBOARD_MONTHLY_CASE =
  "setLoadingDashboardMonthlyCase";
export const SET_LOADING_SUBMISSION_METHOD_CASE =
  "setLoadingSubmissionMethodCase";
export const SET_LOADING_SPECIAL_EVENT_CASE = "setLoadingSpecialEventCase";
export const SET_LOADING_LATEST_UPDATE = "setLoadingLatestUpdate";
export const SET_LOADING_CASE_BY_COUNTRY = "setLoadingCaseByCountry";

export const SET_LOADING_ISSUE_DATA_LIST = "setLoadingIssueDataList";
export const SET_LOADING_DETAIL_DATA_LIST = "setLoadingDetailDataList";
export const SET_LOADING_RIGHT_DATA_LIST = "setLoadingRightDataList";
export const SET_LOADING_VICTIM_DATA_LIST = "setLoadingVictimDataList";
export const SET_LOADING_COMPLAINANT_DATA_LIST =
  "setLoadingComplainantDataList";
export const SET_LOADING_DEFENDANT_DATA_LIST = "setLoadingDefendantDataList";
export const SET_LOADING_SPECIAL_EVENT_DATA_LIST =
  "setLoadingSpecialEventDataList";


export const SET_CONSULTATION_LOADING = "setConsultationLoading";
export const SET_CONSULTATION_THEME_LOADING = "setConsultationThemeLoading";
export const SET_CONSULTATION_MEDIA_LOADING = "setConsultationMediaLoading";
export const SET_CONSULTATION_ISSUE_LOADING = "setConsultationIssueLoading";

export default {
  state: {
    isConsultationLoading: false,
    loadingConsultationThemeData: false,
    loadingConsultationMediaData: false,
    loadingConsultationIssueData: false,
    isLoading: false,
    loadingDashboardData: false,
    loadingDashboardMapsData: false,
    loadingDashboardTotalCase: false,
    loadingDashboardYearlyCase: false,
    loadingRightClassificationCase: false,
    loadingIssueCase: false,
    loadingCaseDetail: false,
    loadingClaimantClassification: false,
    loadingVictimClassification: false,
    loadingDefendantClassification: false,
    loadingDashboardMonthlyCase: false,
    loadingSubmissionMethodCase: false,
    loadingSpecialEventCase: false,
    loadingLatestUpdate: false,
    loadingCaseByCountry: false,
    loadingIssueDataList: false,
    loadingDetailDataList: false,
    loadingRightDataList: false,
    loadingVictimDataList: false,
    loadingComplainantDataList: false,
    loadingDefendantDataList: false,
    loadingSpecialEventDataList: false,
  },
  getters: {
    getLoadingState: (state) => {
      return state.isLoading;
    },
    getLoadingConsultationState: (state) => {
      return state.isConsultationLoading;
    },
  },
  actions: {
    [SET_LOADING](context, status) {
      context.commit(SET_LOADING, status);
    },
    [SET_CONSULTATION_LOADING](context, status) {
      context.commit(SET_CONSULTATION_LOADING, status);
    },
    [SET_LOADING_DASHBOARD_MONTHLY_CASE](context, status) {
      context.commit(SET_LOADING_DASHBOARD_MONTHLY_CASE, status);
      context.commit(SET_LOADING, status);
    },
  },
  mutations: {
    [SET_CONSULTATION_LOADING](state, status) {
      if (status === true) {
        state.isConsultationLoading = true;
      } else {
        if (
            state.loadingConsultationThemeData === status &&
            state.loadingConsultationIssueData === status &&
            state.loadingConsultationMediaData === status
        ) {
          state.isConsultationLoading = status;
        }
      }
    },
    [SET_LOADING](state, status) {
      if (status === true) {
        state.isLoading = true;
      } else {
        if (
          state.loadingDashboardData === status &&
          state.loadingDashboardMapsData === status &&
          state.loadingDashboardTotalCase === status &&
          state.loadingDashboardYearlyCase === status &&
          state.loadingRightClassificationCase === status &&
          state.loadingRightClassificationCase === status &&
          state.loadingIssueCase === status &&
          state.loadingCaseDetail === status &&
          state.loadingClaimantClassification === status &&
          state.loadingVictimClassification === status &&
          state.loadingVictimClassification === status &&
          state.loadingDefendantClassification === status &&
          state.loadingDashboardMonthlyCase === status &&
          state.loadingSubmissionMethodCase === status &&
          state.loadingSpecialEventCase === status &&
          state.loadingLatestUpdate === status &&
          state.loadingCaseByCountry === status &&
          state.loadingIssueDataList === status &&
          state.loadingDetailDataList === status &&
          state.loadingRightDataList === status &&
          state.loadingVictimDataList === status &&
          state.loadingComplainantDataList === status &&
          state.loadingDefendantDataList === status &&
          state.loadingSpecialEventDataList === status
        ) {
          state.isLoading = status;
        }
      }
    },
    [SET_CONSULTATION_THEME_LOADING](state, status) {
      if (state.loadingConsultationThemeData !== status) {
        state.loadingConsultationThemeData = status;
      }
    },
    [SET_CONSULTATION_ISSUE_LOADING](state, status) {
      if (state.loadingConsultationIssueData !== status) {
        state.loadingConsultationIssueData = status;
      }
    },
    [SET_CONSULTATION_MEDIA_LOADING](state, status) {
      if (state.loadingConsultationMediaData !== status) {
        state.loadingConsultationMediaData = status;
      }
    },
    [SET_CONSULTATION_THEME_LOADING](state, status) {
      if (state.loadingConsultationThemeData !== status) {
        state.loadingConsultationThemeData = status;
      }
    },
    [SET_LOADING_DASHBOARD_MONTHLY_CASE](state, status) {
      if (state.loadingDashboardMonthlyCase !== status) {
        state.loadingDashboardMonthlyCase = status;
      }
    },
    [SET_LOADING_DASHBOARD_YEARLY_CASE](state, status) {
      if (state.loadingDashboardYearlyCase !== status) {
        state.loadingDashboardYearlyCase = status;
      }
    },
    [SET_LOADING_DASHBOARD_DATA](state, status) {
      if (state.loadingDashboardData !== status) {
        state.loadingDashboardData = status;
      }
    },
    [SET_LOADING_DASHBOARD_MAPS_DATA](state, status) {
      if (state.loadingDashboardMapsData !== status) {
        state.loadingDashboardMapsData = status;
      }
    },
    [SET_LOADING_DASHBOARD_TOTAL_CASE](state, status) {
      if (state.loadingDashboardTotalCase !== status) {
        state.loadingDashboardTotalCase = status;
      }
    },
    [SET_LOADING_LATEST_UPDATE](state, status) {
      if (state.latestUpdate !== status) {
        state.latestUpdate = status;
      }
    },
    [SET_LOADING_RIGHT_CLASSIFICATION_CASE](state, status) {
      if (state.loadingRightClassificationCase !== status) {
        state.loadingRightClassificationCase = status;
      }
    },
    [SET_LOADING_ISSUE_CASE](state, status) {
      if (state.loadingIssueCase !== status) {
        state.loadingIssueCase = status;
      }
    },
    [SET_LOADING_CASE_DETAIL](state, status) {
      if (state.loadingCaseDetail !== status) {
        state.loadingCaseDetail = status;
      }
    },
    [SET_LOADING_CLAIMANT_CLASSIFICATION](state, status) {
      if (state.loadingClaimantClassification !== status) {
        state.loadingClaimantClassification = status;
      }
    },
    [SET_LOADING_VICTIM_CLASSIFICATION](state, status) {
      if (state.loadingVictimClassification !== status) {
        state.loadingVictimClassification = status;
      }
    },
    [SET_LOADING_DEFENDANT_CLASSIFICATION](state, status) {
      if (state.loadingDefendantClassification !== status) {
        state.loadingDefendantClassification = status;
      }
    },
    [SET_LOADING_SUBMISSION_METHOD_CASE](state, status) {
      if (state.loadingSubmissionMethodCase !== status) {
        state.loadingSubmissionMethodCase = status;
      }
    },
    [SET_LOADING_SPECIAL_EVENT_CASE](state, status) {
      if (state.loadingSpecialEventCase !== status) {
        state.loadingSpecialEventCase = status;
      }
    },
    [SET_LOADING_CASE_BY_COUNTRY](state, status) {
      if (state.loadingCaseByCountry !== status) {
        state.loadingCaseByCountry = status;
      }
    },
    [SET_LOADING_ISSUE_DATA_LIST](state, status) {
      if (state.loadingIssueDataList !== status) {
        state.loadingIssueDataList = status;
      }
    },
    [SET_LOADING_DETAIL_DATA_LIST](state, status) {
      if (state.loadingDetailDataList !== status) {
        state.loadingDetailDataList = status;
      }
    },
    [SET_LOADING_RIGHT_DATA_LIST](state, status) {
      if (state.loadingRightDataList !== status) {
        state.loadingRightDataList = status;
      }
    },
    [SET_LOADING_VICTIM_DATA_LIST](state, status) {
      if (state.loadingVictimDataList !== status) {
        state.loadingVictimDataList = status;
      }
    },
    [SET_LOADING_COMPLAINANT_DATA_LIST](state, status) {
      if (state.loadingComplainantDataList !== status) {
        state.loadingComplainantDataList = status;
      }
    },
    [SET_LOADING_DEFENDANT_DATA_LIST](state, status) {
      if (state.loadingDefendantDataList !== status) {
        state.loadingDefendantDataList = status;
      }
    },
    [SET_LOADING_SPECIAL_EVENT_DATA_LIST](state, status) {
      if (state.loadingSpecialEventDataList !== status) {
        state.loadingSpecialEventDataList = status;
      }
    },
  },
};
