import ApiService from "@/core/services/api.service";

export const GET_GRAPH_DATA = "getGraphData";

// mutation type
export const SET_GRAPH_DATA = "setGraphData";
export const SET_GRAPH_DATA_LOADING = "setGraphDataLoading";

const state = {
  graphData: [],
  graphDataLoading: false,
};

const getters = {
  getGraphData: (state) => {
    return state.graphData;
  },
  getGraphLoading: (state) => {
    return state.graphDataLoading;
  },
};

const actions = {
  [GET_GRAPH_DATA](context) {
    context.commit(SET_GRAPH_DATA_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post(`api/smartmap/get_graph_data`)
        .then(({ data }) => {
          context.commit(SET_GRAPH_DATA, data.data);
          context.commit(SET_GRAPH_DATA_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_GRAPH_DATA_LOADING, false);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_GRAPH_DATA](state, data) {
    state.graphData = data;
  },
  [SET_GRAPH_DATA_LOADING](state, status) {
    state.userGuideLoading = status;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
