import ApiService from "@/core/services/api.service";
import {
  SET_CONSULTATION_LOADING,
  SET_CONSULTATION_THEME_LOADING,
  SET_CONSULTATION_ISSUE_LOADING,
  SET_CONSULTATION_MEDIA_LOADING,
} from "./loading.module";

// action types
export const GET_ISSUE_CASE = "getConsultationIssueCase";
export const GET_THEME_CASE = "getConsultationThemeCase";
export const GET_MEDIA_CASE = "getConsultationMediaCase";
export const GET_CONSULTATION_TOTAL_CASE = "getConsultationTotalCase";
export const GET_DASHBOARD_DATA = "getDashboardData";
export const SET_GEO_JSON_FEATURES = "setGeoJsonFeatures";
export const SET_GEO_JSON_FEATURES_EMPTY = "setGeoJsonFeaturesEmpty";

export const SET_FILTER_CONSULTATION = "setFilterConsultation";
export const RESET_FILTER_CONSULTATION = "resetFilterConsultation";

// mutation types
export const SET_ISSUE_CASE = "setConsultationIssueCase";
export const SET_THEME_CASE = "setConsultationThemeCase";
export const SET_MEDIA_CASE = "setConsultationMediaCase";
export const SET_CONSULTATION_TOTAL_CASE = "setConsultationTotalCase";

export const SET_DASHBOARD_DATA = "setDashboardData";
export const SET_SELECTED_MONTH = "setSelectedMonth";
export const SET_SELECTED_PROVINCE = "setSelectedProvince";
export const SET_REQUEST_TYPE = "setRequestType";

const state = {
  consultationIssueCase: [],
  consultationThemeCase: [],
  consultationMediaCase: [],
  dashboardCardData: [],
  caseDataByState: [],
  yearConsultationSelected: null,
  monthConsultationSelected: null,
  provinceConsultationSelected: null,
  countryConsultationSelected: null,
  issueConsultationSelected: null,
  mediaConsultationSelected: null,
  themeConsultationSelected: null,
  totalConsultationCase: 0,
  submissionMethodCase: [],
  latestUpdate: Date.now(),
  geoJsonFeatures: [],
  filterDashboard: [],
  requestType: 0,
  sendSurvey: [],
};

const getters = {
  getConsultationDashboardCardStatus: (state) => (cardName) => {
    return state.dashboardCardData.filter(function (elem) {
      return elem.name === cardName;
    })[0];
  },
  getConsultationSelectedYear: (state) => {
    return state.yearConsultationSelected;
  },
  getConsultationSelectedMonth: (state) => {
    return state.monthConsultationSelected;
  },
  getConsultationSelectedProvince: (state) => {
    return state.provinceConsultationSelected;
  },
  getConsultationSelectedProvinceName: (state) => {
    if (state.provinceConsultationSelected === 0 || state.provinceConsultationSelected === undefined) {
      return "Indonesia";
    }

    return state.caseDataByState.filter(function (elem) {
      return elem.id === state.provinceConsultationSelected;
    })[0].name;
  },
  getConsultationTotalCase: (state) => {
    return state.totalConsultationCase;
  },
  getConsultationIssueCase: (state) => {
    return state.consultationIssueCase;
  },
  getConsultationThemeCase: (state) => {
    return state.consultationThemeCase;
  },
  getConsultationMediaCase: (state) => {
    return state.consultationMediaCase;
  },
  getConsultationPostParams: (state) => {
    return {
      type: state.requestType,
      year: state.yearConsultationSelected,
      month: state.monthConsultationSelected,
      province: state.provinceConsultationSelected,
      advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
    };
  },
};

const actions = {
  [GET_DASHBOARD_DATA](context) {
    // context.commit(SET_LOADING_DASHBOARD_DATA, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_cases_data_card", {
        type: state.requestType,
        year: state.yearConsultationSelected,
        month: state.monthConsultationSelected,
        province: state.provinceConsultationSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_DATA, data.data);
          // context.commit(SET_LOADING_DASHBOARD_DATA, false);
          // context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [SET_FILTER_CONSULTATION](context, payload) {
    // if (payload.province !== null)
    // context.commit(SET_SELECTED_PROVINCE, payload.province);
    context.commit(SET_FILTER_CONSULTATION, payload);
    context.dispatch(GET_CONSULTATION_TOTAL_CASE);
    context.dispatch(GET_ISSUE_CASE);
    context.dispatch(GET_THEME_CASE);
    context.dispatch(GET_MEDIA_CASE);
  },
  [SET_SELECTED_MONTH](context, payload) {
    context.commit(SET_SELECTED_MONTH, payload);
    context.dispatch(GET_CONSULTATION_TOTAL_CASE);
    context.dispatch(GET_ISSUE_CASE);
    context.dispatch(GET_THEME_CASE);
    context.dispatch(GET_MEDIA_CASE);
  },
  [SET_SELECTED_PROVINCE](context, payload) {
    context.commit(SET_SELECTED_PROVINCE, payload);
    context.dispatch(GET_CONSULTATION_TOTAL_CASE);
    context.dispatch(GET_ISSUE_CASE);
    context.dispatch(GET_THEME_CASE);
    context.dispatch(GET_MEDIA_CASE);
  },
  [GET_CONSULTATION_TOTAL_CASE](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_consultation_total", {
        month: state.monthConsultationSelected,
        year: state.yearConsultationSelected,
        province: state.provinceConsultationSelected,
        country: state.countryConsultationSelected,
        media: state.mediaConsultationSelected,
        theme: state.themeConsultationSelected,
        issue: state.issueConsultationSelected
      })
          .then(({ data }) => {
            context.commit(SET_CONSULTATION_TOTAL_CASE, data.total);
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  [GET_THEME_CASE](context) {
    context.commit(SET_CONSULTATION_THEME_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_consultation_theme", {
        month: state.monthConsultationSelected,
        year: state.yearConsultationSelected,
        province: state.provinceConsultationSelected,
        country: state.countryConsultationSelected,
        media: state.mediaConsultationSelected,
        theme: state.themeConsultationSelected,
        issue: state.issueConsultationSelected
      })
        .then(({ data }) => {
          // console.log(data.data);
          context.commit(SET_THEME_CASE, data.data);
          context.commit(SET_CONSULTATION_THEME_LOADING, false);
          context.commit(SET_CONSULTATION_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_ISSUE_CASE](context) {
    context.commit(SET_CONSULTATION_ISSUE_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_consultation_issue", {
        month: state.monthConsultationSelected,
        year: state.yearConsultationSelected,
        province: state.provinceConsultationSelected,
        country: state.countryConsultationSelected,
        media: state.mediaConsultationSelected,
        theme: state.themeConsultationSelected,
        issue: state.issueConsultationSelected
      })
        .then(({ data }) => {
          context.commit(SET_ISSUE_CASE, data.data);
          context.commit(SET_CONSULTATION_ISSUE_LOADING, false);
          context.commit(SET_CONSULTATION_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_MEDIA_CASE](context) {
    context.commit(SET_CONSULTATION_MEDIA_LOADING, true);
    return new Promise((resolve, reject) => {
      // console.log("filter ="+state.mediaSelected);
      ApiService.post("api/smartmap/get_consultation_media", {
        month: state.monthConsultationSelected,
        year: state.yearConsultationSelected,
        province: state.provinceConsultationSelected,
        country: state.countryConsultationSelected,
        media: state.mediaConsultationSelected,
        theme: state.themeConsultationSelected,
        issue: state.issueConsultationSelected
      })
        .then(({ data }) => {
          context.commit(SET_MEDIA_CASE, data.data);
          context.commit(SET_CONSULTATION_MEDIA_LOADING, false);
          context.commit(SET_CONSULTATION_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [SET_GEO_JSON_FEATURES](context, param) {
    context.commit(SET_GEO_JSON_FEATURES, param);
  },
  [SET_GEO_JSON_FEATURES_EMPTY](context) {
    context.commit(SET_GEO_JSON_FEATURES_EMPTY);
  },
  [RESET_FILTER_CONSULTATION](context, param) {
    context.commit(RESET_FILTER_CONSULTATION, param);
  },
};

const mutations = {
  [SET_DASHBOARD_DATA](state, data) {
    state.dashboardCardData = data;
  },
  [SET_SELECTED_MONTH](state, data) {
    state.monthSelected = data;
  },
  [SET_SELECTED_PROVINCE](state, data) {
    state.provinceSelected = data;
  },
  [SET_CONSULTATION_TOTAL_CASE](state, data) {
    state.totalConsultationCase = data;
  },
  [SET_ISSUE_CASE](state, data) {
    state.consultationIssueCase = data;
  },
  [SET_THEME_CASE](state, data) {
    state.consultationThemeCase = data;
  },
  [SET_MEDIA_CASE](state, data) {
    state.consultationMediaCase = data;
  },
  [SET_GEO_JSON_FEATURES](state, param) {
    state.geoJsonFeatures.push(param);
  },
  [SET_GEO_JSON_FEATURES_EMPTY](state) {
    state.geoJsonFeatures = [];
  },
  [SET_FILTER_CONSULTATION](state, payload) {
    // if (payload.province !== null) state.provinceSelected = payload.province;
    state.countryConsultationSelected = payload.country;
    state.provinceConsultationSelected = payload.province;
    state.monthConsultationSelected = payload.month;
    state.yearConsultationSelected = payload.year;
    state.mediaConsultationSelected = payload.media;
    state.issueConsultationSelected = payload.issue;
    state.themeConsultationSelected = payload.theme;
    state.filterDashboard = payload;
  },
  [RESET_FILTER_CONSULTATION](state, payload) {
    state.countryConsultationSelected = payload.country;
    state.provinceConsultationSelected = payload.province;
    state.monthConsultationSelected = payload.month;
    state.yearConsultationSelected = payload.year;
    state.mediaConsultationSelected = payload.media;
    state.issueConsultationSelected = payload.issue;
    state.themeConsultationSelected = payload.theme;
    state.filterDashboard = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
