import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const EMAIL_AUTH = "emailAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const SET_LOADING_LOGIN = "setLoadingLogin";
export const GET_SURVEY_STATUS = "getSurveyStatus";
export const GET_EDUCATION = "getEducation";
export const GET_GENDER = "getGender";
export const GET_IDENTITY_TYPE = "getIdentityType";
export const GET_CATEGORY_PEMOHON = "getCategoryPemohon";
export const GET_UNIT_KERJA = "getUnitKerja";
export const GET_KIRIM_INFORMASI = "getKirimInformasi";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_USER_INFO = "setUserInfo";
export const SET_USER_ONLINE = "setUserOnline";
export const SET_USER_OFFLINE = "setUserOffline";
export const SET_USER_STATISTIC = "setUserStatistic";
export const SET_IS_SURVEY_STATUS = "setSurveyStatus";
export const SET_IS_EMAIL_AUTH = "setEmailAuth";
export const SET_EDUCATION = "setEducation";
export const SET_GENDER = "setGender";
export const SET_IDENTITY_TYPE = "setIdentityType";
export const SET_UNIT_KERJA = "setUnitKerja";
export const SET_CATEGORY_PEMOHON = "setCategoryPemohon";
export const SET_KIRIM_INFORMASI = "setKirimInformasi";
export const SET_PERCENT_SURVEY = "setSurveyPercent";
export const SET_SUCCESS_ACTION = "setSuccessAction";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  isLoading: false,
  educations: [],
  gender: [],
  identityType: [],
  categoryPemohon: [],
  unitKerja: [],
  kirimInformasi: [],
  userStatistic: {},
  percentSurvey: {},
  isSuccess: false,
  isEmailAuth: false
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getUserStatistic(state) {
    return state.userStatistic;
  },
  getPercentSurvey(state) {
    return state.percentSurvey;
  },
  getLoadingInfo: state => {
    return state.isLoading;
  },
  getError: state => {
    return state.errors;
  },
  getEducations: state => {
    return state.educations;
  },
  getGender: state => {
    return state.gender;
  },
  getIdentityType: state => {
    return state.identityType;
  },
  getUnitKerja: state => {
    return state.unitKerja;
  },
  getCategoryPemohon: state => {
    return state.categoryPemohon;
  },
  getKirimInformasi: state => {
    return state.kirimInformasi;
  },
  getSuccessStatus: state => {
    return state.isSuccess;
  },
  getEmailAuth: state => {
    return state.isEmailAuth;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_LOGIN, true);
      ApiService.post("api/login", {
        username: credentials.email,
        password: credentials.password,
        device_info: "SmartMap-CLient",
        remember_me : 0
      })
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            context.commit(SET_LOADING_LOGIN, false);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            context.commit(SET_LOADING_LOGIN, false);
            reject(response);
          });
    });
  },
  [EMAIL_AUTH](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_LOGIN, true);
      ApiService.post("/api/smartmap/register/check_emailexist", {
        email: credentials.email
      })
          .then(({ data }) => {
            context.commit(SET_LOADING_LOGIN, false);
            context.commit(SET_IS_EMAIL_AUTH, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            context.commit(SET_IS_EMAIL_AUTH, false);
            context.commit(SET_LOADING_LOGIN, false);
            reject(response);
          });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            context.commit(SET_SUCCESS_ACTION, true);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
            context.commit(SET_SUCCESS_ACTION, false);
            reject(response);
          });
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_LOGIN, true);
      ApiService.setHeader();
      // ApiService.get("api/smartmap_user_info")
      ApiService.get("api/user/smartmapinfo")
          .then(({ data }) => { // DS 26-05-2022
            context.commit(SET_USER_INFO, data);
            context.commit(SET_LOADING_LOGIN, false);
            context.commit(SET_SUCCESS_ACTION, true);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            context.commit(SET_LOADING_LOGIN, false);
            context.commit(SET_SUCCESS_ACTION, false);
            reject(response);
          });
    });
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
  [SET_USER_ONLINE](context, session_id) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/set_online", {
        session_id: session_id
      })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
    });
  },
  [SET_USER_OFFLINE](context, session_id) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/set_user_offline", {
        session_id: session_id
      })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
    });
  },
  [SET_USER_STATISTIC](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/smartmap/user_statistic")
          .then(({ data }) => {
            context.commit(SET_USER_STATISTIC, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
    });
  },
  [SET_PERCENT_SURVEY](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmapadmin/persentase_survey/list")
          .then(({ data }) => {
            context.commit(SET_PERCENT_SURVEY, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
    });
  },
  [GET_EDUCATION](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/constant/educations")
          .then(({ data }) => {
            context.commit(SET_EDUCATION, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
    });
  },
  [GET_GENDER](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/constant/gender")
          .then(({ data }) => {
            context.commit(SET_GENDER, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
    });
  },
  [GET_IDENTITY_TYPE](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/constant/identity_card")
          .then(({ data }) => {
            context.commit(SET_IDENTITY_TYPE, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
    });
  },
  [GET_CATEGORY_PEMOHON](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/constant/kategori_pemohon")
          .then(({ data }) => {
            context.commit(SET_CATEGORY_PEMOHON, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
    });
  },
  [GET_UNIT_KERJA](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/constant/unit_kerja")
          .then(({ data }) => {
            context.commit(SET_UNIT_KERJA, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
    });
  },
  [GET_KIRIM_INFORMASI](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/constant/kirim_informasi")
          .then(({ data }) => {
            context.commit(SET_KIRIM_INFORMASI, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject(response);
          });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    if (error === undefined) state.errors = "MESSAGES.AUTH.VERIFICATION";
    if (!error) return;
    state.errors = error;
    if (error.status === 422 && error.data.message === "unauthenticated") state.errors = "MESSAGES.AUTH.INVALID_ACCOUNT";
    if (error.status === 401) state.errors = "MESSAGES.AUTH.INVALID_ACCOUNT";
    if (error.status === 500) state.errors = "MESSAGES.SERVER_ERROR";
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.errors = {};
    JwtService.saveToken(data.data[0].auth_token);
  },
  [SET_USER_INFO](state, user) {
    state.user = user;
    localStorage.setItem("user_info", JSON.stringify(user));
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    localStorage.clear();
  },
  [SET_USER_STATISTIC](state, userStatis) {
    state.userStatistic = userStatis;
    localStorage.setItem("userStatistic", JSON.stringify(userStatis));
  },
  [SET_PERCENT_SURVEY](state, data) {
    state.percentSurvey = data.data[0];
  },
  [SET_LOADING_LOGIN](state, status) {
    state.isLoading = status;
  },
  [SET_EDUCATION](state, data) {
    state.educations = data;
  },
  [SET_GENDER](state, data) {
    state.gender = data;
  },
  [SET_IDENTITY_TYPE](state, data) {
    state.identityType = data;
  },
  [SET_CATEGORY_PEMOHON](state, data) {
    state.categoryPemohon = data;
  },
  [SET_UNIT_KERJA](state, data) {
    state.unitKerja = data;
  },
  [SET_KIRIM_INFORMASI](state, data) {
    state.kirimInformasi = data;
  },
  [SET_SUCCESS_ACTION](state, data) {
    state.isSuccess = data;
  },
  [SET_IS_EMAIL_AUTH](state, data) {
    state.isEmailAuth = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
