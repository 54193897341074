import ApiService from "@/core/services/api.service";
import {
  SET_LOADING,
  SET_LOADING_CASE_BY_COUNTRY,
  SET_LOADING_CASE_DETAIL,
  SET_LOADING_CLAIMANT_CLASSIFICATION,
  SET_LOADING_DASHBOARD_DATA,
  SET_LOADING_DASHBOARD_MAPS_DATA,
  SET_LOADING_DASHBOARD_MONTHLY_CASE,
  SET_LOADING_DASHBOARD_TOTAL_CASE,
  SET_LOADING_DASHBOARD_YEARLY_CASE,
  SET_LOADING_DEFENDANT_CLASSIFICATION,
  SET_LOADING_ISSUE_CASE,
  SET_LOADING_LATEST_UPDATE,
  SET_LOADING_RIGHT_CLASSIFICATION_CASE,
  SET_LOADING_SPECIAL_EVENT_CASE,
  SET_LOADING_SUBMISSION_METHOD_CASE,
  SET_LOADING_VICTIM_CLASSIFICATION,
} from "./loading.module";

// action types
export const GET_DASHBOARD_DATA = "getDashboardData";
export const GET_DASHBOARD_MAPS_DATA = "getDashboardMapsData";
export const GET_DASHBOARD_TOTAL_CASE = "getDashboardTotalCase";
export const GET_DASHBOARD_YEARLY_CASE = "getDashboardYearlyCase";
export const GET_RIGHT_CLASSIFICATION_CASE =
  "getDashboardRightClassificationCase";
export const GET_ISSUE_CASE = "getDashboardIssueCase";
export const GET_CASE_DETAIL = "getCaseDetailCase";
export const GET_CLAIMANT_CLASSIFICATION = "getDashboardClaimantClassfication";
export const GET_VICTiM_CLASSIFICATION = "getDashboardVictimClassification";
export const GET_DEFENDANT_CLASSIFICTION =
  "getDashboardDefendantClassification";
export const GET_DASHBOARD_MONTHLY_CASE = "getDashboardMonthlyCase";
export const GET_SUBMISSION_METHOD_CASE = "getSubmissionMethodCase";
export const GET_SPECIAL_EVENT_CASE = "getSpecialEventCase";
export const GET_LATEST_UPDATE = "getLatestUpdate";
export const GET_CASE_BY_COUNTRY = "getCaseByCountry";

export const SET_GEO_JSON_FEATURES = "setGeoJsonFeatures";
export const SET_GEO_JSON_FEATURES_EMPTY = "setGeoJsonFeaturesEmpty";

export const SET_FILTER_DASHBOARD = "setFilterDashboard";
export const RESET_FILTER_DASHBOARD = "resetFilterDashboard";

// mutation types
export const SET_DASHBOARD_DATA = "setDashboardData";
export const SET_DASHBOARD_MAPS_DATA = "setDashboardMapsData";
export const SET_SELECTED_MONTH = "setSelectedMonth";
export const SET_SELECTED_PROVINCE = "setSelectedProvince";
export const SET_DASHBOARD_TOTAL_CASE = "setDashboardTotalCase";
export const SET_DASHBOARD_YEARLY_CASE = "setDashboardYearlyCase";
export const SET_DASHBOARD_MONTHLY_CASE = "setDashboardMonthlyCase";
export const SET_RIGHT_CLASSIFICATION_CASE =
  "setDashboardRightClassificationCase";
export const SET_ISSUE_CASE = "setDashboardIssueCase";
export const SET_CASE_DETAIL_CASE = "setCaseDetailCase";
export const SET_CLAIMANT_CLASSIFICATION = "setDashboardClaimantClassification";
export const SET_VICTIM_CLASSIFICATION = "setDashboardVictimClassification";
export const SET_DEFENDANT_CLASSIFICATION =
  "setDashboardDefendantClassification";
export const SET_DASHBOARD_SUBMISSION_METHOD_CASE =
  "setDashboardSubmissionMethodCase";
export const SET_SPECIAL_EVENT_CASE = "setSpecialEventCase";
export const SET_LATEST_UPDATE = "setLatestUpdate";
export const SET_REQUEST_TYPE = "setRequestType";
export const SET_CASE_BY_COUNTRY = "setCaseByCountry";
export const SURVEY_POST = "sendSurvey";
export const LANDING_PAGE = "landingPageConfig";

const state = {
  dashboardCardData: [],
  caseDataByState: [],
  monthSelected: 0,
  yearSelected: new Date().getFullYear(),
  provinceSelected: 0,
  totalCase: 0,
  categoriesYearlyCase: [],
  seriesYearlyCase: [],
  categoryMonthlyCase: [],
  seriesMonthlyCase: [],
  rightClassificationCase: [],
  issueCase: [],
  claimantClassification: [],
  victimClassification: [],
  defendantClassification: [],
  submissionMethodCase: [],
  caseDetailCase: [],
  specialEventCase: [],
  countryCase: [],
  latestUpdate: Date.now(),
  geoJsonFeatures: [],
  filterDashboard: [],
  requestType: 0,
  sendSurvey: [],
  landingPageConfig: [],
};

const getters = {
  getDashboardCardStatus: (state) => (cardName) => {
    return state.dashboardCardData.filter(function (elem) {
      return elem.name === cardName;
    })[0];
  },
  getDataByStateId: (state) => (stateId) => {
    let result = {};
    state.caseDataByState.forEach(function (value) {
      if (value.id === stateId) {
        result = value;
      }
    });
    return result;
  },
  getSelectedYear: (state) => {
    return state.yearSelected;
  },
  getSelectedMonth: (state) => {
    return state.monthSelected;
  },
  getSelectedProvince: (state) => {
    return state.provinceSelected;
  },
  getSelectedProvinceName: (state) => {
    if (state.provinceSelected === 0 || state.provinceSelected === undefined) {
      return "Indonesia";
    }

    return state.caseDataByState.filter(function (elem) {
      return elem.id === state.provinceSelected;
    })[0].name;
  },
  getTotalCase: (state) => {
    return state.totalCase;
  },
  getCategoriesYearlyCase: (state) => {
    return state.categoriesYearlyCase;
  },
  getSeriesYearlyCase: (state) => {
    return state.seriesYearlyCase;
  },
  getCategoryMonthlyCase: (state) => {
    return state.categoryMonthlyCase;
  },
  getSeriesMonthlyCase: (state) => {
    return state.seriesMonthlyCase;
  },
  getRightClassificationCase: (state) => {
    return state.rightClassificationCase;
  },
  getIssueCase: (state) => {
    return state.issueCase;
  },
  getCaseDetailCase: (state) => {
    return state.caseDetailCase;
  },
  getClaimantClassification: (state) => {
    return state.claimantClassification;
  },
  getVictimClassification: (state) => {
    return state.victimClassification;
  },
  getDefendantClassification: (state) => {
    return state.defendantClassification;
  },
  getSubmissionMethodCase: (state) => {
    return state.submissionMethodCase;
  },
  getSpecialEventCase: (state) => {
    return state.specialEventCase;
  },
  getCaseByCountry: (state) => {
    return state.countryCase;
  },
  getLatestUpdate: (state) => {
    return state.latestUpdate;
  },
  getPostParams: (state) => {
    return {
      type: state.requestType,
      year: state.yearSelected,
      month: state.monthSelected,
      province: state.provinceSelected,
      advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
    };
  },
};

const actions = {
  [GET_DASHBOARD_DATA](context) {
    context.commit(SET_LOADING_DASHBOARD_DATA, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_cases_data_card", {
        type: state.requestType,
        year: state.yearSelected,
        month: state.monthSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_DATA, data.data);
          context.commit(SET_LOADING_DASHBOARD_DATA, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_DASHBOARD_MAPS_DATA](context) {
    context.commit(SET_LOADING_DASHBOARD_MAPS_DATA, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_maps_data", {
        type: state.requestType,
        year: state.yearSelected,
        month: state.monthSelected,
        province: 0,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_MAPS_DATA, data.data);
          context.commit(SET_LOADING_DASHBOARD_MAPS_DATA, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_DASHBOARD_TOTAL_CASE](context) {
    context.commit(SET_LOADING_DASHBOARD_TOTAL_CASE, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_total_case", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_TOTAL_CASE, data.data);
          context.commit(SET_LOADING_DASHBOARD_TOTAL_CASE, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_LATEST_UPDATE](context) {
    context.commit(SET_LOADING_LATEST_UPDATE, true);
    return new Promise((resolve, reject) => {
      ApiService.get("api/smartmap/get_last_calculation_date")
        .then(({ data }) => {
          context.commit(SET_LATEST_UPDATE, data);
          context.commit(SET_LOADING_LATEST_UPDATE, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [SET_FILTER_DASHBOARD](context, payload) {
    if (payload.province !== null)
      context.commit(SET_SELECTED_PROVINCE, payload.province);

    context.commit(SET_FILTER_DASHBOARD, payload);

    context.dispatch(GET_DASHBOARD_MAPS_DATA);
    context.dispatch(GET_DASHBOARD_TOTAL_CASE);
    context.dispatch(GET_DASHBOARD_YEARLY_CASE);
    context.dispatch(GET_RIGHT_CLASSIFICATION_CASE);
    context.dispatch(GET_ISSUE_CASE);
    context.dispatch(GET_CLAIMANT_CLASSIFICATION);
    context.dispatch(GET_VICTiM_CLASSIFICATION);
    context.dispatch(GET_DEFENDANT_CLASSIFICTION);
    context.dispatch(GET_DASHBOARD_MONTHLY_CASE);
    context.dispatch(GET_SUBMISSION_METHOD_CASE);
    context.dispatch(GET_CASE_DETAIL);
    context.dispatch(GET_SPECIAL_EVENT_CASE);
    context.dispatch(GET_CASE_BY_COUNTRY);
  },
  [SET_SELECTED_MONTH](context, payload) {
    context.commit(SET_SELECTED_MONTH, payload);
    // context.dispatch(GET_DASHBOARD_DATA);
    context.dispatch(GET_DASHBOARD_MAPS_DATA);
    context.dispatch(GET_DASHBOARD_TOTAL_CASE);
    context.dispatch(GET_DASHBOARD_YEARLY_CASE);
    context.dispatch(GET_RIGHT_CLASSIFICATION_CASE);
    context.dispatch(GET_ISSUE_CASE);
    context.dispatch(GET_CLAIMANT_CLASSIFICATION);
    context.dispatch(GET_VICTiM_CLASSIFICATION);
    context.dispatch(GET_DEFENDANT_CLASSIFICTION);
    context.dispatch(GET_DASHBOARD_MONTHLY_CASE);
    context.dispatch(GET_SUBMISSION_METHOD_CASE);
    context.dispatch(GET_CASE_DETAIL);
    context.dispatch(GET_SPECIAL_EVENT_CASE);
  },
  [SET_SELECTED_PROVINCE](context, payload) {
    context.commit(SET_SELECTED_PROVINCE, payload);
    // context.dispatch(GET_DASHBOARD_DATA);
    context.dispatch(GET_DASHBOARD_MAPS_DATA);
    context.dispatch(GET_DASHBOARD_TOTAL_CASE);
    context.dispatch(GET_DASHBOARD_YEARLY_CASE);
    context.dispatch(GET_RIGHT_CLASSIFICATION_CASE);
    context.dispatch(GET_ISSUE_CASE);
    context.dispatch(GET_CLAIMANT_CLASSIFICATION);
    context.dispatch(GET_VICTiM_CLASSIFICATION);
    context.dispatch(GET_DEFENDANT_CLASSIFICTION);
    context.dispatch(GET_DASHBOARD_MONTHLY_CASE);
    context.dispatch(GET_SUBMISSION_METHOD_CASE);
    context.dispatch(GET_CASE_DETAIL);
    context.dispatch(GET_SPECIAL_EVENT_CASE);
  },
  [GET_DASHBOARD_YEARLY_CASE](context) {
    context.commit(SET_LOADING_DASHBOARD_YEARLY_CASE, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_case_statistic_data", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_YEARLY_CASE, data.data);
          context.commit(SET_LOADING_DASHBOARD_YEARLY_CASE, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_DASHBOARD_MONTHLY_CASE](context) {
    context.commit(SET_LOADING_DASHBOARD_MONTHLY_CASE, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_case_statistic_by_month_data", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_MONTHLY_CASE, data.data);
          context.commit(SET_LOADING_DASHBOARD_MONTHLY_CASE, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_RIGHT_CLASSIFICATION_CASE](context) {
    context.commit(SET_LOADING_RIGHT_CLASSIFICATION_CASE, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_right_classification_data", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_RIGHT_CLASSIFICATION_CASE, data.data);
          context.commit(SET_LOADING_RIGHT_CLASSIFICATION_CASE, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_ISSUE_CASE](context) {
    context.commit(SET_LOADING_ISSUE_CASE, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_issue_data", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_ISSUE_CASE, data.data);
          context.commit(SET_LOADING_ISSUE_CASE, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_CASE_DETAIL](context) {
    context.commit(SET_LOADING_CASE_DETAIL, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_case_detail_data", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_CASE_DETAIL_CASE, data.data);
          context.commit(SET_LOADING_CASE_DETAIL, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_CLAIMANT_CLASSIFICATION](context) {
    context.commit(SET_LOADING_CLAIMANT_CLASSIFICATION, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_claimant_classification_data", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_CLAIMANT_CLASSIFICATION, data.data);
          context.commit(SET_LOADING_CLAIMANT_CLASSIFICATION, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_VICTiM_CLASSIFICATION](context) {
    context.commit(SET_LOADING_VICTIM_CLASSIFICATION, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_victim_classification_data", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_VICTIM_CLASSIFICATION, data.data);
          context.commit(SET_LOADING_VICTIM_CLASSIFICATION, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_DEFENDANT_CLASSIFICTION](context) {
    context.commit(SET_LOADING_DEFENDANT_CLASSIFICATION, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_defendant_classification_data", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_DEFENDANT_CLASSIFICATION, data.data);
          context.commit(SET_LOADING_DEFENDANT_CLASSIFICATION, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_SUBMISSION_METHOD_CASE](context) {
    context.commit(SET_LOADING_SUBMISSION_METHOD_CASE, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_submission_method_case", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_SUBMISSION_METHOD_CASE, data.data);
          context.commit(SET_LOADING_SUBMISSION_METHOD_CASE, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_SPECIAL_EVENT_CASE](context) {
    context.commit(SET_LOADING_SPECIAL_EVENT_CASE, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/get_special_event_case", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_SPECIAL_EVENT_CASE, data.data);
          context.commit(SET_LOADING_SPECIAL_EVENT_CASE, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_CASE_BY_COUNTRY](context) {
    context.commit(SET_LOADING_CASE_BY_COUNTRY, true);
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/case_by_country", {
        type: state.requestType,
        month: state.monthSelected,
        year: state.yearSelected,
        province: state.provinceSelected,
        advanceFilter: state.requestType === 1 ? state.filterDashboard : null,
      })
        .then(({ data }) => {
          context.commit(SET_CASE_BY_COUNTRY, data.data);
          context.commit(SET_LOADING_CASE_BY_COUNTRY, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [SET_GEO_JSON_FEATURES](context, param) {
    context.commit(SET_GEO_JSON_FEATURES, param);
  },
  [SET_GEO_JSON_FEATURES_EMPTY](context) {
    context.commit(SET_GEO_JSON_FEATURES_EMPTY);
  },
  [SURVEY_POST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("api/smartmap/survey", {
        survey_email: param?.email,
        survey_value: param?.values,
        survey_gender: param?.gender,
        survey_age: param?.age,
        survey_education: param?.education,
        survey_advice: param?.advice,
      })
        .then(({ data }) => {
          context.commit(SURVEY_POST, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [RESET_FILTER_DASHBOARD](context, param) {
    context.commit(RESET_FILTER_DASHBOARD, param);
  },
  [LANDING_PAGE](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/smartmap/landingpage/config")
        .then(({ data }) => {
          context.commit(LANDING_PAGE, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_DASHBOARD_DATA](state, data) {
    state.dashboardCardData = data;
  },
  [SET_DASHBOARD_MAPS_DATA](state, data) {
    var i = 1;
    data.filter(function (elem) {
      if (i >= 1 && i <= 5 && elem.data > 0) elem.color = "#9E0E0B";
      if (i >= 6 && i <= 34 && elem.data > 0) elem.color = "#FFFC52";
      if (elem.data === 0) elem.color = "#55CC08";
      i++;
      return true;
    });
    state.caseDataByState = data;
  },
  [SET_SELECTED_MONTH](state, data) {
    state.monthSelected = data;
  },
  [SET_SELECTED_PROVINCE](state, data) {
    state.provinceSelected = data;
  },
  [SET_DASHBOARD_TOTAL_CASE](state, data) {
    state.totalCase = data;
  },
  [SET_DASHBOARD_YEARLY_CASE](state, data) {
    let categories = [];
    let series = [];
    data.forEach((x) => {
      categories.push(x.name);
      series.push(x.data);
    });
    state.categoriesYearlyCase = categories;
    state.seriesYearlyCase = series;
  },
  [SET_DASHBOARD_MONTHLY_CASE](state, data) {
    let categories = [];
    let series = [];
    data.forEach((x) => {
      let date = new Date(state.yearSelected, x.id - 1, 1);
      let locale = localStorage.getItem("language") || "id";
      categories.push(date.toLocaleString(locale, { month: "long" }));
      series.push(x.data);
    });
    state.categoryMonthlyCase = categories;
    state.seriesMonthlyCase = series;
  },
  [SET_RIGHT_CLASSIFICATION_CASE](state, data) {
    state.rightClassificationCase = data;
  },
  [SET_ISSUE_CASE](state, data) {
    state.issueCase = data;
  },
  [SET_CASE_DETAIL_CASE](state, data) {
    state.caseDetailCase = data;
  },
  [SET_CLAIMANT_CLASSIFICATION](state, data) {
    state.claimantClassification = data;
  },
  [SET_VICTIM_CLASSIFICATION](state, data) {
    state.victimClassification = data;
  },
  [SET_DEFENDANT_CLASSIFICATION](state, data) {
    state.defendantClassification = data;
  },
  [SET_DASHBOARD_SUBMISSION_METHOD_CASE](state, data) {
    state.submissionMethodCase = data;
  },
  [SET_SPECIAL_EVENT_CASE](state, data) {
    state.specialEventCase = data;
  },
  [SET_CASE_BY_COUNTRY](state, data) {
    state.countryCase = data;
  },
  [SET_LATEST_UPDATE](state, data) {
    state.latestUpdate = data;
  },
  [SET_GEO_JSON_FEATURES](state, param) {
    state.geoJsonFeatures.push(param);
  },
  [SET_GEO_JSON_FEATURES_EMPTY](state) {
    state.geoJsonFeatures = [];
  },
  [SET_FILTER_DASHBOARD](state, payload) {
    if (payload.province !== null) state.provinceSelected = payload.province;
    state.countrySelected = payload.country;
    state.monthSelected = payload.month;
    state.yearSelected = payload.year;
    state.filterDashboard = payload;
    state.requestType = 1;
  },
  [RESET_FILTER_DASHBOARD](state, payload) {
    state.provinceSelected = payload.province;
    state.countrySelected = payload.country;
    state.monthSelected = payload.month;
    state.yearSelected = payload.year;
    state.filterDashboard = payload;
    state.requestType = 0;
  },
  [SURVEY_POST](state, payload) {
    state.sendSurvey = payload;
  },
  [LANDING_PAGE](state, data) {
    state.landingPageConfig = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
