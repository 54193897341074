// action types
import ApiService from "@/core/services/api.service";

export const GET_USER_GUIDE = "getUserGuide";

// mutation type
export const SET_USER_GUIDE = "setUserGuide";
export const SET_USER_GUIDE_LOADING = "setUserGuideLoading";

const state = {
  userGuideData: [],
  userGuideLoading: false,
};

const getters = {
  getUserGuideData: (state) => {
    return state.userGuideData;
  },
  getUserGuideLoading: (state) => {
    return state.userGuideLoading;
  },
};

const actions = {
  [GET_USER_GUIDE](context) {
    context.commit(SET_USER_GUIDE_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post(`api/smartmap/get_user_guide`)
        .then(({ data }) => {
          context.commit(SET_USER_GUIDE, data.data);
          context.commit(SET_USER_GUIDE_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_USER_GUIDE_LOADING, false);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_USER_GUIDE](state, data) {
    state.userGuideData = data;
  },
  [SET_USER_GUIDE_LOADING](state, status) {
    state.userGuideLoading = status;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
