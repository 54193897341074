import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import menuConfig from "./menu.module";
import province from "./province.module";
import dashboard from "./dashboard.module";
import generalDefinition from "./faq/general_definition.module";
import rebuttalStatement from "./faq/rebuttal_statement.module";
import userGuide from "./faq/user_guide.monule";
import actualData from "./periodic-data/actual-data.module";
import graphData from "./periodic-data/graph-data.module";
import dashboardFilter from "./dashboard-filter.module";
import complaintdata from "./complaintdata.module";
import loading from "./loading.module";
import consultation from "./consultation.module";
import consultationFilter from "./consultation-filter.module";
// import createLogger from "vuex/dist/logger";
// import createLogger from "vuex/dist/logger";
// import data from "./data.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    menuConfig,
    province,
    dashboard,
    generalDefinition,
    rebuttalStatement,
    userGuide,
    actualData,
    graphData,
    dashboardFilter,
    complaintdata,
    loading,
    consultation,
    consultationFilter
    // data
  },
});
