// action type
import ApiService from "@/core/services/api.service";
import {
  SET_LOADING,
  SET_LOADING_COMPLAINANT_DATA_LIST,
  SET_LOADING_DEFENDANT_DATA_LIST,
  SET_LOADING_DETAIL_DATA_LIST,
  SET_LOADING_ISSUE_DATA_LIST,
  SET_LOADING_RIGHT_DATA_LIST,
  SET_LOADING_SPECIAL_EVENT_DATA_LIST,
  SET_LOADING_VICTIM_DATA_LIST,
} from "./loading.module";

export const GET_ISSUE_DATA_LIST = "getIssueDataList";
export const GET_DETAIL_CASES = "getDetailCases";
export const GET_RIGHT = "getRight";
export const GET_VICTIM = "getVictim";
export const GET_COMPLAINANT = "getComplainantType";
export const GET_DEFENDANT = "getDefendantType";
export const GET_SPECIAL_EVENT = "getSpecialEvent";
export const SET_ISSUE_SELECTED = "setIssueSelected";
export const SET_RIGHT_SELECTED = "setRightSelected";

// mutation type
export const SET_ISSUE_DATA_LIST = "setIssueDataList";
export const SET_DETAIL_CASES = "setDetailCases";
export const SET_RIGHT = "setRight";
export const SET_VICTIM = "setVictim";
export const SET_COMPLAINANT = "setComplainantType";
export const SET_DEFENDANT_TYPE = "setDefendantType";
export const SET_SPECIAL_EVENT = "setSpecialEvent";

const state = {
  issueList: [],
  caseDetails: [],
  rights: [],
  victims: [],
  complainantType: [],
  defendantType: [],
  specialEvent: [],
  issueSelected: 0,
  rightSelected: 0,
};

const getters = {
  getIssueData: (state) => {
    return state.issueList;
  },
  getCaseDetails: (state) => {
    return state.caseDetails;
  },
  getRight: (state) => {
    return state.rights;
  },
  getVictim: (state) => {
    return state.victims;
  },
  getComplainantType: (state) => {
    return state.complainantType;
  },
  getDefendantType: (state) => {
    return state.defendantType;
  },
  getSpecialEvent: (state) => {
    return state.specialEvent;
  },
};

const actions = {
  [GET_ISSUE_DATA_LIST](context) {
    context.commit(SET_LOADING, true);
    context.commit(SET_LOADING_ISSUE_DATA_LIST, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("api/issue/list", {
        sort: {
          issue_name: "asc",
        },
        pages: 10000,
      })
        .then(({ data }) => {
          context.commit(SET_ISSUE_DATA_LIST, data.data);
          context.commit(SET_LOADING_ISSUE_DATA_LIST, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_DETAIL_CASES](context) {
    context.commit(SET_LOADING, true);
    context.commit(SET_LOADING_DETAIL_DATA_LIST, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("api/smartmapadmin/case_detail/list", {
        sort: {
          name: "asc",
        },
        filter: {
          issue_id: context.state.issueSelected,
        },
        pages: 10000,
      })
        .then(({ data }) => {
          context.commit(SET_DETAIL_CASES, data);
          context.commit(SET_LOADING_DETAIL_DATA_LIST, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_RIGHT](context) {
    context.commit(SET_LOADING, true);
    context.commit(SET_LOADING_RIGHT_DATA_LIST, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("api/right/list", {
        sort: {
          right_name: "asc",
        },
        pages: 10000,
      })
        .then(({ data }) => {
          context.commit(SET_RIGHT, data.data);
          context.commit(SET_LOADING_RIGHT_DATA_LIST, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_VICTIM](context) {
    context.commit(SET_LOADING, true);
    context.commit(SET_LOADING_VICTIM_DATA_LIST, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("api/victim_type/list", {
        sort: {
          victim_type_name: "asc",
        },
        pages: 10000,
      })
        .then(({ data }) => {
          context.commit(SET_VICTIM, data.data);
          context.commit(SET_LOADING_VICTIM_DATA_LIST, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_COMPLAINANT](context) {
    context.commit(SET_LOADING, true);
    context.commit(SET_LOADING_COMPLAINANT_DATA_LIST, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("api/complainant_type/list", {
        sort: {
          complainant_type_name: "asc",
        },
        pages: 10000,
      })
        .then(({ data }) => {
          context.commit(SET_COMPLAINANT, data.data);
          context.commit(SET_LOADING_COMPLAINANT_DATA_LIST, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_DEFENDANT](context) {
    context.commit(SET_LOADING, true);
    context.commit(SET_LOADING_DEFENDANT_DATA_LIST, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("api/complainant_type/list", {
        sort: {
          complainant_type_name: "asc",
        },
        pages: 10000,
      })
        .then(({ data }) => {
          context.commit(SET_DEFENDANT_TYPE, data.data);
          context.commit(SET_LOADING_DEFENDANT_DATA_LIST, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_SPECIAL_EVENT](context) {
    context.commit(SET_LOADING, true);
    context.commit(SET_LOADING_SPECIAL_EVENT_DATA_LIST, true);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("api/peristiwa_khusus/list", {
        sort: {
          nama_peristiwa: "asc",
        },
        pages: 10000,
      })
        .then(({ data }) => {
          context.commit(SET_SPECIAL_EVENT, data.data);
          context.commit(SET_LOADING_SPECIAL_EVENT_DATA_LIST, false);
          context.commit(SET_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [SET_ISSUE_SELECTED](context, param) {
    context.commit(SET_ISSUE_SELECTED, param);
    context.dispatch(GET_DETAIL_CASES);
  },
  [SET_RIGHT_SELECTED](context, param) {
    context.commit(SET_RIGHT_SELECTED, param);
  },
};

const mutations = {
  [SET_ISSUE_DATA_LIST](state, data) {
    state.issueList = data;
  },
  [SET_DETAIL_CASES](state, data) {
    state.caseDetails = data;
  },
  [SET_ISSUE_SELECTED](state, data) {
    state.issueSelected = data;
  },
  [SET_RIGHT](state, data) {
    state.rights = data;
  },
  [SET_RIGHT_SELECTED](state, data) {
    state.rightSelected = data;
  },
  [SET_VICTIM](state, data) {
    state.victims = data;
  },
  [SET_COMPLAINANT](state, data) {
    state.complainantType = data;
  },
  [SET_DEFENDANT_TYPE](state, data) {
    state.defendantType = data;
  },
  [SET_SPECIAL_EVENT](state, data) {
    state.specialEvent = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
