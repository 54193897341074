// action type
import ApiService from "@/core/services/api.service";

export const GET_GENERAL_DEFINITION = "getGeneralDefinition";
export const SET_GENERAL_DEFINITION_PAGE = "setGeneralDefinitionPage";
export const SET_GENERAL_DEFINITION_FILTER = "setGeneralDefinitionFilter";
export const SET_GENERAL_DEFINITION_SORT = "setGeneralDefinitionSort";

// mutation type
export const SET_GENERAL_DEFINITION = "setGeneralDefinition";
export const SET_GENERAL_DEFINITION_LOADING = "setGeneralDefinitionLoading";

const state = {
  generalDefinitionData: [],
  generalDefinitionCurrentPage: 0,
  generalDefinitionTotalPage: 0,
  generalDefinitionPerPage: 10,
  generalDefinitionTotalRow: 0,
  generalDefinitionIsLoading: false,
  generalDefinitionFilter: "",
  generalDefinitionSort: {}
};

const getters = {
  getGeneralDefinitionData: state => {
    return state.generalDefinitionData;
  },
  getGeneralDefinitionLoadingStatus: state => {
    return state.generalDefinitionIsLoading;
  },
  getGeneralDefinitionCurrentPage: state => {
    return state.generalDefinitionCurrentPage;
  },
  getGeneralDefinitionTotalPage: state => {
    return state.generalDefinitionTotalPage;
  },
  getGeneralDefinitionPerPage: state => {
    return state.generalDefinitionPerPage;
  },
  getGeneralDefinitionTotalRow: state => {
    return state.generalDefinitionTotalRow;
  }
};

const actions = {
  [GET_GENERAL_DEFINITION](context) {
    context.commit(SET_GENERAL_DEFINITION_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post(
        `api/smartmap/get_general_definition?page=${state.generalDefinitionCurrentPage}`,
        {
          "filter": state.generalDefinitionFilter ?? "",
          "sort": state.generalDefinitionSort,
          "pages": state.generalDefinitionPerPage
        }
      )
        .then(({ data }) => {
          context.commit(SET_GENERAL_DEFINITION, data);
          context.commit(SET_GENERAL_DEFINITION_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_GENERAL_DEFINITION_LOADING, false);
          reject(response);
        });
    });
  },
  [SET_GENERAL_DEFINITION_PAGE](context, page) {
    context.commit(SET_GENERAL_DEFINITION_PAGE, page);
    context.dispatch(GET_GENERAL_DEFINITION);
  },
  [SET_GENERAL_DEFINITION_FILTER](context, text) {
    context.commit(SET_GENERAL_DEFINITION_FILTER, text);
    context.dispatch(GET_GENERAL_DEFINITION);
  },
  [SET_GENERAL_DEFINITION_SORT](context, param) {
    context.commit(SET_GENERAL_DEFINITION_SORT, param);
    context.dispatch(GET_GENERAL_DEFINITION);
  }
};

const mutations = {
  [SET_GENERAL_DEFINITION](state, data) {
    state.generalDefinitionData = data.data;
    state.generalDefinitionCurrentPage = data.meta.current_page;
    state.generalDefinitionTotalPage = data.meta.total_page;
    state.generalDefinitionTotalRow = data.meta.total;
  },
  [SET_GENERAL_DEFINITION_PAGE](state, page) {
    state.generalDefinitionCurrentPage = page;
  },
  [SET_GENERAL_DEFINITION_FILTER](state, page) {
    state.generalDefinitionFilter = page;
  },
  [SET_GENERAL_DEFINITION_SORT](state, data) {
    state.generalDefinitionSort = data;
  },
  [SET_GENERAL_DEFINITION_LOADING](state, status) {
    state.generalDefinitionIsLoading = status;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
