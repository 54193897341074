// action type
import ApiService from "@/core/services/api.service";

export const GET_REBUTTAL_STATEMENT = "getRebuttalStatement";

// mutation type
export const SET_REBUTTAL_STATEMENT = "setRebuttalStatement";
export const SET_REBUTTAL_STATEMENT_LOADING = "setRebuttalStatementLoading";

const state = {
  rebuttalStatementData: [],
  rebuttalStatementLoading: false,
};

const getters = {
  getRebuttalStatement: (state) => {
    return state.rebuttalStatementData;
  },
};

const actions = {
  [GET_REBUTTAL_STATEMENT](context) {
    context.commit(SET_REBUTTAL_STATEMENT_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post(`api/smartmap/get_rebuttal_statement`)
        .then(({ data }) => {
          context.commit(SET_REBUTTAL_STATEMENT, data);
          context.commit(SET_REBUTTAL_STATEMENT_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_REBUTTAL_STATEMENT_LOADING, false);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_REBUTTAL_STATEMENT](state, data) {
    state.rebuttalStatementData = data.data;
  },
  [SET_REBUTTAL_STATEMENT_LOADING](state, status) {
    state.rebuttalStatementLoading = status;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
